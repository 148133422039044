import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { CorrelationPeriod, ITimeseriesDataItem } from '@libs/common/models/basicModels';
import { ChartArea, ChartConfiguration, ChartDataset, ChartType } from 'chart.js';
import * as moment from 'moment-timezone';
import { isFalseNumber } from '@libs/common/utils/utils';
import { ANALYSIS_LINE_DASH_STYLES, LINE_TOOLTIP_COLOR } from '@cityair/modules/analysis/constants';
import { GASES } from '@libs/common/consts/mmt-with-pdk-sorted.conts';
import { MeasureZones, measureZones } from '@libs/common/helpers/measure-zones';
import {
    DEFAULT_METEO_VALUES_COLOR,
    METEO_VALUES_COLORS,
} from '@libs/shared-ui/components/timeline-panel/constants';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import {
    correctLabelTooltip,
    createTooltipTrFirst,
} from '@cityair/modules/analysis/components/analysis-map/line-chart/tooltip-helper';
import {
    createElement,
    getOrCreateTooltip,
} from '@libs/shared-ui/components/timeline-panel/chart-timeline/utils/tooltip-helper';
import { formatNumber } from '@angular/common';
import { shortDateFormat } from '@libs/common/utils/date-formats';

type ChartPoint = {
    x: string;
    y: number;
};
function getGradient(
    ctx: CanvasRenderingContext2D,
    chartArea: ChartArea,
    mmt: string,
    pinsZones: MeasureZones,
    data: number[]
) {
    const result = [...data];
    const min = Math.min(...result);
    const max = Math.max(...result);
    if (pinsZones) {
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        const countEndPoints = pinsZones.getIndex(mmt, max);
        gradient.addColorStop(0, pinsZones.getColor(min, mmt));
        for (let k = 1; k <= countEndPoints - 2; k++) {
            gradient.addColorStop(k / countEndPoints, pinsZones.getColorByIndex(mmt, k + 1));
        }
        gradient.addColorStop(1, pinsZones.getColor(max, mmt));
        return gradient;
    }
}
function getGradientCorrelation(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, '#9369D7');
    gradient.addColorStop(0.6, '#9369D7');
    gradient.addColorStop(1, '#29CB91');
    return gradient;
}
@Component({
    selector: 'cityair-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements OnChanges {
    @Input() timeline: ITimeseriesDataItem[];
    @Input() dates: string[];
    @Input() correlationData: number[];
    @Input() mmt: string;
    @Input() title?: string;
    @Input() isLoading: boolean;
    @Input() emptyMsg: string;
    @Input() period: CorrelationPeriod;
    @Output() sizeUpdate = new EventEmitter<{ left: number; width: number }>();
    @Output() closeChart = new EventEmitter();

    constructor(private _changeDetectorRef: ChangeDetectorRef) {}
    public texts = TEXTS.ANALYSIS;
    public currentLang = LANGUAGE;
    chartTitle: string;
    hoverLinePosition = 0;
    hoverLineVisible = false;
    dashLinesMap: {
        [key: string]: number;
    } = {};
    private externalTooltipHandlerCorrelation = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;

        // do not show empty tooltips
        if (!tooltip.dataPoints || tooltip.dataPoints.length === 0) {
            return;
        }

        const tooltipEl = getOrCreateTooltip(chart);
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b) => b.lines);
            document.body.querySelector('.chart_tooltip__title')?.remove();
            const tableHead = createElement('div', 'chart_tooltip__title');

            titleLines.forEach((title) => {
                const div = createElement('div');
                const timeText =
                    this.period === CorrelationPeriod.month
                        ? title.replace(',', '')
                        : title.split(',')[0];
                div.appendChild(document.createTextNode(timeText));
                tableHead.appendChild(div);
            });

            const tableBody = document.createElement('tbody');
            const index = tooltip.dataPoints[0]?.dataIndex ?? null;
            if (index !== null && this.chartData.length === 2) {
                const tr1 = createTooltipTrFirst(
                    [this.chartData[0].label],
                    ANALYSIS_LINE_DASH_STYLES[0]
                );
                const value = this.chartData[0].data[index]?.y ?? TEXTS.LIST_OM.noData;
                const tr2 = this.createTooltipTrSecond(LINE_TOOLTIP_COLOR[0], [
                    this.chartData[0].label + ': ' + value,
                ]);
                tableBody.appendChild(tr1);
                tableBody.appendChild(tr2);
                const tr3 = createTooltipTrFirst(
                    [this.chartData[1].label],
                    ANALYSIS_LINE_DASH_STYLES[1]
                );
                const value1 = this.chartData[1].data[index]?.y ?? TEXTS.LIST_OM.noData;
                const tr4 = this.createTooltipTrSecond(LINE_TOOLTIP_COLOR[1], [
                    this.chartData[1].label + ': ' + value1,
                ]);
                tableBody.appendChild(tr3);
                tableBody.appendChild(tr4);
            }
            bodyLines.forEach((body, i) => {
                const tr3 = this.createTooltipTrCorrelation(LINE_TOOLTIP_COLOR[i], body);
                tableBody.appendChild(tr3);
            });

            const tableRoot = tooltipEl.querySelector('table');
            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.before(tableHead);
            tableRoot.appendChild(tableBody);
            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            if (tooltip.caretX < chart.width / 2) {
                tooltipEl.style.left = positionX + tooltip.caretX + tooltip.width / 2 + 75 + 'px';
            } else {
                tooltipEl.style.left = positionX + tooltip.caretX - tooltip.width / 2 - 75 + 'px';
            }
            tooltipEl.style.top = positionY - 20 + 'px';
            tooltipEl.style.width = 'max-content';
            tooltipEl.style.whiteSpace = 'nowrap';
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding =
                tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        }
    };

    private externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;

        // do not show empty tooltips
        if (!tooltip.dataPoints || tooltip.dataPoints.length === 0) {
            return;
        }

        const tooltipEl = getOrCreateTooltip(chart);
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }
        // Set Text
        if (tooltip.body) {
            const currentTime = tooltip.dataPoints[0]?.raw?.x;
            const data = shortDateFormat(currentTime, LANGUAGE);
            const titleLines = [data];

            const bodyLines = tooltip.body.map((b) => b.lines);
            bodyLines.length = 2;

            document.body.querySelector('.chart_tooltip__title')?.remove();
            const tableHead = createElement('div', 'chart_tooltip__title');

            titleLines.forEach((title) => {
                const div = createElement('div');
                const timeText =
                    this.period === CorrelationPeriod.month
                        ? title.replace(',', '')
                        : title.split(',')[0];
                div.appendChild(document.createTextNode(timeText));
                tableHead.appendChild(div);
            });

            const tableBody = document.createElement('tbody');
            const allValues: string[] = [];
            bodyLines.forEach((body, i) => {
                const value = tooltip.dataPoints[i].parsed.y;
                const key = this.mmt;
                const tr1 = createTooltipTrFirst(body, ANALYSIS_LINE_DASH_STYLES[i]);
                const tr2 = this.createTooltipTrSecond(LINE_TOOLTIP_COLOR[i], body);
                tableBody.appendChild(tr1);
                tableBody.appendChild(tr2);
            });
            const index = tooltip.dataPoints[0]?.dataIndex ?? null;
            const x = tooltip.dataPoints[0].raw?.x;
            const value = this.correlationChartData[0].data.find((v) => v.x === x);
            if (this.correlationChartData.length) {
                const textValue = value ? value?.y : TEXTS.LIST_OM.noData;
                const tr3 = this.createTooltipTrCorrelation(LINE_TOOLTIP_COLOR[0], [
                    ' : ' + textValue,
                ]);
                tableBody.appendChild(tr3);
            }
            const tableRoot = tooltipEl.querySelector('table');
            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.before(tableHead);
            tableRoot.appendChild(tableBody);
            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            if (tooltip.caretX < chart.width / 2) {
                tooltipEl.style.left = positionX + tooltip.caretX + tooltip.width / 2 + 25 + 'px';
            } else {
                tooltipEl.style.left = positionX + tooltip.caretX - tooltip.width / 2 - 25 + 'px';
            }
            tooltipEl.style.top = positionY - 20 + 'px';
            tooltipEl.style.width = 'max-content';
            tooltipEl.style.whiteSpace = 'nowrap';
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding =
                tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        }
    };

    private createTooltipTrSecond(color, body) {
        const td1 = createElement('td', 'charts_tooltip__td1');

        const span = createElement('span', 'charts_tooltip__color');
        span.style.background = color;
        td1.appendChild(span);

        const span2 = createElement('span');

        const textCorrect = correctLabelTooltip(body[0]);
        const value = textCorrect.value
            ? formatNumber(Number(textCorrect.value), this.currentLang, '1.1-1')
            : TEXTS.LIST_OM.noData;
        span2.innerHTML = TEXTS.NAMES[this.mmt] + ': ' + value;
        td1.appendChild(span2);

        const td2 = createElement('td', 'charts_tooltip__td2');
        td2.innerHTML = '';

        const tr = createElement('tr', 'charts_tooltip__tr');
        tr.appendChild(td1);
        tr.appendChild(td2);

        return tr;
    }

    private createTooltipTrCorrelation(color, body) {
        const td1 = createElement('td', 'charts_tooltip__td1');
        const span2 = createElement('span');

        const textCorrect = correctLabelTooltip(body[0]);
        const text =
            textCorrect.value && textCorrect.value !== ' null'
                ? formatNumber(Number(textCorrect.value), this.currentLang, '1.2-2')
                : TEXTS.LIST_OM.noData;
        span2.innerHTML = this.texts.correlation.chartTitleCorrelation + ': ' + text;
        td1.appendChild(span2);

        const td2 = createElement('td', 'charts_tooltip__td2');
        td2.innerHTML = '';

        const tr = createElement('tr', 'charts_tooltip__tr');
        tr.appendChild(td1);
        tr.appendChild(td2);

        return tr;
    }

    public chartOptions: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 1000,
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 10,
            },
        },
        elements: {
            line: {
                fill: false,
                tension: 0,
                borderWidth: 1,
                borderJoinStyle: 'bevel',
            },
            point: {
                radius: 0,
                hoverRadius: 0,
                hitRadius: 10,
            },
        },
        scales: {
            x: {
                type: 'time',
                ticks: {
                    display: false,
                },
                time: {
                    tooltipFormat: 'DD MMM, HH:mm',
                    parser: (value: string) => moment(value).valueOf(),
                },
                grid: {
                    display: false,
                },
            },

            y: {
                ticks: {
                    display: false,
                },
                border: {
                    display: false,
                },
                grid: {
                    color: '#e6e6e6',
                    tickLength: 0,
                },
            },
        },
        onHover: (e, _, chart) => {
            const activeElements = chart.getElementsAtEventForMode(
                e.native,
                'index',
                { intersect: false },
                false
            );
            const element = activeElements[0];
            if (element) {
                const { datasetIndex, index } = element;
                const meta = chart.getDatasetMeta(datasetIndex);

                if (index > 0 && index < meta.data.length - 1) {
                    this.hoverLinePosition = meta.data[index].x + 10;
                    this.hoverLineVisible = true;
                    this._changeDetectorRef.detectChanges();
                }
            }
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                enabled: false,
                external: this.externalTooltipHandler,
            },
        },
    };
    public chartOptionsCorrelation: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 1000,
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 10,
            },
        },
        elements: {
            line: {
                fill: false,
                tension: 0,
                borderWidth: 1,
                borderJoinStyle: 'bevel',
            },
            point: {
                radius: 0,
                hoverRadius: 0,
                hitRadius: 10,
            },
        },
        scales: {
            x: {
                type: 'time',
                ticks: {
                    display: false,
                },
                time: {
                    tooltipFormat: 'DD MMM, HH:mm',
                    parser: (value: string) => moment(value).valueOf(),
                },
                grid: {
                    display: false,
                },
            },

            y: {
                ticks: {
                    display: false,
                },
                position: 'right',
                min: -1,
                max: 1,
                border: {
                    display: false,
                },
                grid: {
                    color: '#e6e6e6',
                    tickLength: 0,
                },
            },
        },
        onHover: (e, _, chart) => {
            const activeElements = chart.getElementsAtEventForMode(
                e.native,
                'index',
                { intersect: false },
                false
            );
            const element = activeElements[0];
            if (element) {
                const { datasetIndex, index } = element;
                const meta = chart.getDatasetMeta(datasetIndex);

                if (index > 0 && index < meta.data.length - 1) {
                    this.hoverLinePosition = meta.data[index].x + 10;
                    this.hoverLineVisible = true;
                    this._changeDetectorRef.detectChanges();
                }
            }
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                enabled: false,
                external: this.externalTooltipHandlerCorrelation,
            },
        },
    };
    public chartType: ChartType = 'line';
    chartData: ChartDataset<ChartType, ChartPoint[]>[] = [];
    correlationChartData: ChartDataset<ChartType, ChartPoint[]>[] = [];
    plugins: ChartConfiguration['plugins'] = [
        {
            id: 'adjustScales',
            afterUpdate: (chart) => {
                const xScale = chart.scales?.x;
                const data = this.chartData[0]?.data;
                const len = data?.length;
                if (len && xScale) {
                    const val1 = data[1].x;
                    const valN = data[len - 2].x;
                    const x1 = xScale.getPixelForValue(moment(val1).valueOf());
                    const xN = xScale.getPixelForValue(moment(valN).valueOf());
                    const delta = (xN - x1) / (len - 1);
                    if (delta !== 0 && x1 > 0) {
                        this.sizeUpdate.emit({
                            left: x1 - delta / 2,
                            width: delta * (len + 1),
                        });
                    }
                }
            },
        },
    ];
    isEmptyData = true;
    ngOnChanges(changes: SimpleChanges) {
        if (
            changes?.timeline?.currentValue?.length ||
            changes?.correlationData?.currentValue?.length
        ) {
            if (this.dates?.length && this.correlationData?.length && this.timeline?.length) {
                const names = this.timeline.map((v) => v?.name);
                this.chartTitle = names?.join(' — ');
                this.createChartData();
            }
        }
    }

    public close() {
        this.closeChart.emit();
    }

    private createChartData() {
        const result = [];
        this.correlationChartData = [];
        const dates = this.dates ?? [];
        this.timeline?.forEach((item, i) => {
            const timeline = item?.data?.measurements[this.mmt]?.values;
            if (timeline) {
                this.isEmptyData = !timeline.some((v) => v);
                const chartData = this.prepareChartData(timeline, dates);
                const mmt = this.mmt;
                result.push({
                    label: item.name,
                    data: chartData,
                    type: 'line',
                    borderColor: function (context) {
                        if (!GASES.includes(mmt) && measureZones.getZone(mmt)) {
                            const chart = context.chart;
                            const valuesY = chartData.map((v) => v.y);
                            const { ctx, chartArea } = chart;

                            // This case happens on initial chart load
                            if (!chartArea) {
                                return null;
                            }
                            return getGradient(ctx, chartArea, mmt, measureZones, valuesY);
                        } else {
                            return METEO_VALUES_COLORS[mmt] || DEFAULT_METEO_VALUES_COLOR;
                        }
                    },
                    borderDash: ANALYSIS_LINE_DASH_STYLES[i],
                });
            }
        });

        const data = this.prepareChartData(this.correlationData, this.dates, 2);
        if (data.length) {
            this.correlationChartData.push({
                label: this.texts.correlation.chartTitleCorrelation,
                data: data,
                type: 'line',
                borderColor: function (context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea) {
                        return null;
                    }

                    return getGradientCorrelation(ctx, chartArea);
                },
            });
        }

        this.chartData = result;
    }

    private prepareChartData(timeline: number[], dates: string[], numberAfterDot?: number) {
        if (!numberAfterDot) {
            numberAfterDot = 1;
        }
        return (
            timeline
                ?.map((y, i) => ({
                    y: !isFalseNumber(y) ? Number(y.toFixed(numberAfterDot)) : null,
                    x: dates[i],
                }))
                .filter((point) => !!point.x) || []
        );
    }
}
