export const SLIDES_DATA = [
    {
        title: 'Здравствуйте!',
        description: [
            'На сайте CityAir произошло важное обновление: теперь управлять своими постами и устройствами и следить за их показателями вы сможете в одной вкладке (а не в двух, как это было раньше)',
        ],
        imgUrl: 'onboarding/facelifting-12-2021/facelifting-slide-1.png',
    },
    {
        title: 'Вкладка «Посты»',
        description: [
            'Смотрите информацию о посте мониторинга, редактируйте координаты, название и описание, удаляйте пост, скачивайте данные — всё в одном месте, не переключаясь в другие разделы',
        ],
        imgUrl: 'onboarding/facelifting-12-2021/facelifting-slide-2.png',
    },
    {
        title: 'Вкладка «Посты»',
        description: [
            'Используйте режим «Источники данных», чтобы получить информацию об устройствах, подключенных посту:',
        ],
        imgUrl: 'onboarding/facelifting-12-2021/facelifting-slide-3.png',
    },
    {
        title: 'Вкладка «Устройства»',
        description: ['Проверяйте исправность оборудования во вкладке «Устройства»'],
        imgUrl: 'onboarding/facelifting-12-2021/facelifting-slide-4.png',
    },
];
