import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
} from '@angular/core';
import { NgLocalization } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import {
    IReport,
    IReportKind,
    REPORTS_PAGES,
    REPORT_TEMPLATE_PARAMS_KEY,
    EXCLUDE_CREATE_FORM_KEY,
    IControl,
} from '@cityair/modules/reports/models';
import { Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { ReportKindService } from '@cityair/modules/reports/services/report-kind.service';
import type { CheckboxItem } from '@libs/common/types/checkbox-item';
import {
    selectCurrentKindNotFound,
    dataForCreateReport,
    selectReportCreateError,
} from '@cityair/modules/reports/store/selectors';
import {
    setCurrentKindId,
    setCurrentKind,
    setKindNotFound,
    addReportError,
    toggleReportKindList,
} from '@cityair/modules/reports/store/actions';
import {
    COMMENT_MAX_LEN,
    COUNTRIES_FOR_TIMEZONE_SELECT,
    FILE_NAME_MAX_LEN,
    TIMEOUT_SHOW_ALERT_MSG,
    THRESHOLD_DEFAULT_VALUE,
    THRESHOLD_MAX_VALUE,
    THRESHOLD_MIN_VALUE,
    THRESHOLD_STEP_VALUE,
    MARKETING_REPORT_KIND,
} from '@cityair/modules/reports/constant';
import { postsListLabels } from '@cityair/modules/notifications/notifications.utils';
import { ReportsService } from '@cityair/modules/reports/services/reports.service';
import * as moment from 'moment-timezone';
import { TEXTS, LANGUAGE, isRU } from '@libs/common/texts/texts';
import { TabModel } from '@libs/common/types/tab-model';
import { getAllMmt } from '@libs/common/utils/utils';
import { Post } from '@libs/common/models/basicModels';
import { selectGroupCreateDate } from '@cityair/modules/core/store/group/group.feature';
import { HttpStatusCode } from '@angular/common/http';

const INTERVAL_SOURCE = 'source';
@Component({
    selector: 'cityscreen-add-report',
    templateUrl: './add-report.component.html',
    styleUrls: ['./add-report.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddReportComponent implements OnDestroy {
    public id: number;
    public currentKind: IReportKind;
    public noFoundTemplate: boolean;
    public moObj: Post[] = [];
    private groupId: string;
    public loadingResponse = false;
    public isShowPostSelect = false;
    selectGroupCreateDate = selectGroupCreateDate;
    public translateText = TEXTS.REPORTS;
    public cancelText = TEXTS.COMMON.cancel;
    public textsPostDevices = TEXTS.POSTS_AND_DEVICES;
    public postMonitoringSearchText = TEXTS.NOTIFICATIONS.searchMoPlaceholderText;
    public selectAllText = TEXTS.NOTIFICATIONS.selectAll;
    public selectedAllText = TEXTS.NOTIFICATIONS.allMonitoringPostsSelected;
    public noSelectText = TEXTS.LIST_USERS.noSelect;
    public labelTexts = TEXTS.REPORTS.formCreate;
    public textsNotification = TEXTS.NOTIFICATIONS;
    public textsTimeNames = TEXTS.REPORTS_TIME_NAMES;
    public textsIntervalTooltip = TEXTS.REPORTS_TIME_NAMES_TOOLTIPS;
    public textsUnitsNames = TEXTS.REPORTS_UNITS_NAMES;
    public thresholdSlider: number;
    public allSubstance: string[] = [];
    public availableSubstance: string[] = [];
    public ngDestroyed$ = new Subject<void>();
    public params: { timeBegin: number; timeEnd: number; moIds: string[] };
    PARAMS_KEY = REPORT_TEMPLATE_PARAMS_KEY;
    editControlPoint: UntypedFormGroup;
    isShowDropdownForStations = false;
    isShowDropdownFormat = false;
    listStations: CheckboxItem[] = [];
    marketingReportId = MARKETING_REPORT_KIND.id;
    public ReportCreateForm: UntypedFormGroup;
    public showDateRange = false;
    public errorResponse: string;
    public lastKeyError: string[] = [];
    public tzList: string[];
    public tzValue;
    public threshold_min_value = THRESHOLD_MIN_VALUE;
    public threshold_max_value = THRESHOLD_MAX_VALUE;
    public threshold_step_value = THRESHOLD_STEP_VALUE;
    public isRu = isRU;
    intervalTabs: TabModel[];
    unitTabs: TabModel[];
    currentIntervalTab: TabModel;
    currentUnitTab: TabModel;
    private isNeedIntervalTabsUpdate = false;
    public postsSelectedMany = true;
    public autoName: string;
    constructor(
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private reportKindService: ReportKindService,
        private reportService: ReportsService,
        public store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private ngLocalization: NgLocalization
    ) {
        this.id = +this.route.snapshot.paramMap.get('id');
        if (this.id === MARKETING_REPORT_KIND.id) {
            this.currentKind = MARKETING_REPORT_KIND;
        } else if (this.id) {
            this.store.dispatch(setCurrentKindId({ id: this.id }));
        } else {
            this.store.dispatch(setKindNotFound({ payload: true }));
        }

        this.initState();
    }

    get formName() {
        return this.ReportCreateForm.get('name');
    }

    get formThreshold() {
        return this.ReportCreateForm.get('threshold');
    }

    get formComment() {
        return this.ReportCreateForm.get('comment');
    }

    @HostListener('window:keydown.enter', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (this.ReportCreateForm.valid && !this.loadingResponse) {
            this.onSubmit();
        }
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.close();
    }

    ngOnDestroy(): void {
        this.store.dispatch(setCurrentKind(null));
        this.store.dispatch(setCurrentKindId(null));
        this.ngDestroyed$.next();
    }

    public onSubmit() {
        this.loadingResponse = true;
        const formData = this.ReportCreateForm.getRawValue();
        const newReportObj: IReport = {
            name: `${formData.name}`,
            template_id: this.currentKind.id,
            params: this.generateParams(formData),
        };

        if (formData.comment) {
            newReportObj.comment = formData.comment;
        }

        this.reportService.add(newReportObj).subscribe((data) => {
            this.loadingResponse = false;
            this.close();
            this.store.dispatch(toggleReportKindList({ payload: false }));
        });
    }

    public close(): void {
        this.router.navigate([`/${REPORTS_PAGES.reports}/${REPORTS_PAGES.reportsList}`]);
    }

    public changeCalendar(time) {
        this.params.timeBegin = time.begin;
        this.params.timeEnd = time.end;
        const currentName = this.ReportCreateForm.get('name')?.value;
        if (currentName === this.autoName) {
            this.autoName = this.autoGenerateReportName();
            this.ReportCreateForm.controls.name.setValue(this.autoName);
        }
    }

    public selectedCheckboxText(num: number = 0) {
        const { selected, post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        if (num === 0) {
            return this.noSelectText;
        } else if (num === this.listStations.length) {
            return this.selectedAllText;
        }

        return [selected[category], num, post[category]].join(' ');
    }

    public getError(field: string): string {
        if (
            this.ReportCreateForm &&
            this.ReportCreateForm.controls[field].invalid &&
            (this.ReportCreateForm.controls[field].dirty ||
                this.ReportCreateForm.controls[field].touched)
        ) {
            if (this.ReportCreateForm.controls[field].errors.required) {
                return field === 'name' ? this.labelTexts.nameError : this.labelTexts.postMoError;
            }

            if (this.ReportCreateForm.controls[field].errors.maxlength) {
                return this.labelTexts.maxLength(
                    field,
                    this.ReportCreateForm.controls[field].errors.maxlength.requiredLength
                );
            }
            if (this.ReportCreateForm.controls[field].errors.max) {
                return this.labelTexts.max(
                    field,
                    this.ReportCreateForm.controls[field].errors.max.max
                );
            }
            if (this.ReportCreateForm.controls[field].errors.min) {
                return this.labelTexts.min(
                    field,
                    this.ReportCreateForm.controls[field].errors.min.min
                );
            }
            if (
                field === this.PARAMS_KEY.measure &&
                this.ReportCreateForm.controls[field].errors?.unavailable
            ) {
                return this.ReportCreateForm.controls[field].errors.message;
            }
            if (this.ReportCreateForm.controls[field].errors?.incorrect) {
                return this.ReportCreateForm.controls[field].errors?.message;
            }
        }

        return '';
    }

    public prepareListMo(): CheckboxItem[] {
        const result = this.moObj.map((s) => ({
            id: s.id,
            label: s.name,
            selected: true,
        }));

        return result;
    }

    public removeFromList(index: number) {
        const label = this.getTags()[index];
        this.listStations.find((s) => {
            if (s.label === label) {
                s.selected = false;
                return true;
            }
        });

        this.listStations = [...this.listStations];
        this.updateIntervalTabs();
        this.updateAvailableSubstance();
    }

    public updateListStation($event) {
        this.listStations = $event;
        this.updateIntervalTabs();
        if (!this.postsSelectedMany) {
            this.isShowDropdownForStations = false;
        }
        this.updateAvailableSubstance();
    }

    public getTags = () => this.listStations.filter((s) => s.selected).map((s) => s.label);

    public postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    public updateSliderValue(event) {
        this.ReportCreateForm.controls.threshold.setValue(event);
    }

    private getMoIds() {
        return this.listStations.filter((s) => s.selected).map((s) => s.id);
    }

    public checkMo() {
        return this.currentKind.params[this.PARAMS_KEY.postIds] && this.getMoIds()?.length === 0;
    }

    private createForm(data: IReportKind) {
        this.autoName = this.autoGenerateReportName();
        this.ReportCreateForm = this.fb.group({
            name: [this.autoName, [Validators.required, Validators.maxLength(FILE_NAME_MAX_LEN)]],
            comment: ['', [Validators.maxLength(COMMENT_MAX_LEN)]],
            threshold: [
                THRESHOLD_DEFAULT_VALUE,
                [
                    Validators.required,
                    Validators.min(THRESHOLD_MIN_VALUE),
                    Validators.max(THRESHOLD_MAX_VALUE),
                ],
            ],
        });
        if (data.params) {
            Object.values(REPORT_TEMPLATE_PARAMS_KEY).forEach((field) => {
                // add control by params expect date range
                if (
                    data.params.hasOwnProperty(field) &&
                    EXCLUDE_CREATE_FORM_KEY.indexOf(field) === -1
                ) {
                    const validator = data.params[field].required ? Validators.required : [];
                    const value = this.getInitFormValue(field, data.params[field]);
                    this.ReportCreateForm.addControl(
                        field,
                        new UntypedFormControl(value, validator)
                    );
                }
            });
            if (
                data.params.hasOwnProperty(REPORT_TEMPLATE_PARAMS_KEY.startDate) &&
                data.params.hasOwnProperty(REPORT_TEMPLATE_PARAMS_KEY.finishDate)
            ) {
                this.showDateRange = true;
            }
        }
    }

    private initState() {
        this.store
            .select(dataForCreateReport)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                if (data) {
                    this.moObj = data.moObj;
                    if (data.template?.params?.[this.PARAMS_KEY.measure]) {
                        this.allSubstance = getAllMmt(data.moObj).filter((v) =>
                            data.template?.params?.[this.PARAMS_KEY.measure].values.includes(v)
                        );
                        this.availableSubstance = [...this.allSubstance];
                    }

                    this.groupId = data.groupId;
                    this.isShowPostSelect = this.moObj.length ? true : false;
                    this.params = {
                        timeBegin: moment().startOf('day').subtract(1, 'months').valueOf(),
                        timeEnd: moment().endOf('day').valueOf(),
                        moIds: this.moObj.map((item) => item.id),
                    };
                    this.tzValue = data.timezone === 'default' ? moment.tz.guess() : data.timezone;
                    this.listStations = this.prepareListMo();
                    this.currentKind = data.template;
                    this.tzList = this.getTZList(this.tzValue);
                    if (data.template?.params?.[this.PARAMS_KEY.intervals]) {
                        const intervals =
                            data.template?.params?.[this.PARAMS_KEY.intervals]?.values;
                        this.isNeedIntervalTabsUpdate = intervals.includes(INTERVAL_SOURCE);
                        this.intervalTabs = intervals.map((v) => ({
                            title: this.textsTimeNames[v] ?? v,
                            type: v,
                            tooltip: this.textsIntervalTooltip[v] ?? '',
                            isDisabled: v === INTERVAL_SOURCE && this.params?.moIds?.length > 1,
                        }));
                        this.currentIntervalTab = this.intervalTabs.find(
                            (v) =>
                                v.type === data.template.params[this.PARAMS_KEY.intervals]?.default
                        );
                    }
                    if (data.template?.params?.[this.PARAMS_KEY.units]) {
                        this.unitTabs = data.template.params[this.PARAMS_KEY.units]?.values.map(
                            (v) => ({
                                title: this.textsUnitsNames[v] ?? v,
                                type: v,
                            })
                        );
                        this.currentUnitTab = this.unitTabs.find((v) => v.type === 'default');
                    }

                    this.createForm(data.template);
                    this._changeDetectorRef.markForCheck();
                }
            });
        this.store
            .select(selectCurrentKindNotFound)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.noFoundTemplate = data;
                this._changeDetectorRef.markForCheck();
                if (data) {
                    setTimeout(() => {
                        this.close();
                        this.store.dispatch(setKindNotFound({ payload: false }));
                    }, TIMEOUT_SHOW_ALERT_MSG);
                }
            });
        this.store
            .select(selectReportCreateError)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((value) => {
                if (value && value.actionData?.payload?.data) {
                    this.reportService.add(value.actionData?.payload?.data).subscribe((data) => {
                        this.loadingResponse = false;
                        this.close();
                        this.store.dispatch(toggleReportKindList({ payload: false }));
                    });
                } else if (value?.formError) {
                    if (value.formError?.error?.status === HttpStatusCode.Unauthorized) {
                        this.errorResponse = this.translateText.error401;
                        this.loadingResponse = false;
                        this.clearError();
                    } else if (value.formError?.error?.status === HttpStatusCode.Forbidden) {
                        this.errorResponse = this.translateText.error403;
                        this.loadingResponse = false;
                        this.clearError();
                    } else {
                        const data = value.formError?.error?.error;
                        for (const key in data) {
                            if (data.hasOwnProperty(key) && this.ReportCreateForm.controls[key]) {
                                this.ReportCreateForm.controls[key].setErrors({
                                    incorrect: true,
                                    message: data[key].join(', '),
                                });
                                this.lastKeyError.push(key);
                            } else if (data.hasOwnProperty('detail')) {
                                this.errorResponse = data.detail;
                                this.loadingResponse = false;
                                this.clearError();
                            }
                        }
                        this.loadingResponse = false;
                    }
                }

                this._changeDetectorRef.markForCheck();
            });
    }

    private getInitFormValue(field: REPORT_TEMPLATE_PARAMS_KEY, obj: IControl) {
        switch (field) {
            case REPORT_TEMPLATE_PARAMS_KEY.postIds: {
                return this.params.moIds;
                break;
            }
            case REPORT_TEMPLATE_PARAMS_KEY.availableFormats: {
                return obj?.default ?? '';
                break;
            }
            case REPORT_TEMPLATE_PARAMS_KEY.measure: {
                const values = this.allSubstance;
                let value = obj.default;
                if (this.allSubstance.length && this.allSubstance.indexOf(obj.default) === -1) {
                    value = this.allSubstance[0];
                }
                return obj?.default === 'all' ? values : [value];
                break;
            }
            case REPORT_TEMPLATE_PARAMS_KEY.timezone: {
                return this.tzValue;
                break;
            }
            case REPORT_TEMPLATE_PARAMS_KEY.threshold:
            case REPORT_TEMPLATE_PARAMS_KEY.year:
            case REPORT_TEMPLATE_PARAMS_KEY.intervals:
            case REPORT_TEMPLATE_PARAMS_KEY.units: {
                return obj?.default;
                break;
            }
            default: {
                return '';
            }
        }
    }

    private clearError() {
        setTimeout(() => {
            this.errorResponse = null;
            this.store.dispatch(addReportError({ payload: null }));
            this._changeDetectorRef.markForCheck();
        }, TIMEOUT_SHOW_ALERT_MSG);
    }

    private autoGenerateReportName() {
        if (
            this.currentKind &&
            this.currentKind.params &&
            this.currentKind?.params[this.PARAMS_KEY.startDate] &&
            this.currentKind?.params[this.PARAMS_KEY.finishDate]
        ) {
            const start = moment(this.params.timeBegin).format('DD.MM.YY');
            const end = moment(this.params.timeEnd).format('DD.MM.YY');
            const name = this.isRu ? this.currentKind.name : this.currentKind.name_eng;
            return `${name}_${start}-${end}`;
        }
        return this.isRu ? `${this.currentKind.name}` : this.currentKind.name_eng;
    }

    public setValueDropDown($event) {
        if ($event.key === this.PARAMS_KEY.timezone) {
            this.tzValue = $event.value;
        } else {
            this.ReportCreateForm.controls[$event.key]?.setValue($event.value);
        }
    }

    public setMeasure($event) {
        this.ReportCreateForm.get('measure').markAsTouched();
        this.ReportCreateForm.controls.measure?.setValue($event);
    }

    setFile(event) {
        this.ReportCreateForm.controls?.fileupload?.setValue(event);
    }

    setTabsInterval(filterTabsInterval: TabModel) {
        this.currentIntervalTab = filterTabsInterval;
        this.ReportCreateForm.controls?.intervals?.setValue(filterTabsInterval.type);
        this.postsSelectedMany = filterTabsInterval.type !== INTERVAL_SOURCE;
    }

    setUnit(unit: TabModel) {
        this.currentUnitTab = unit;
        this.ReportCreateForm.controls?.units?.setValue(unit.type);
    }

    private getTZList(current) {
        let timeZones = [{ name: 'Etc/UTC', offset: 0 }];
        COUNTRIES_FOR_TIMEZONE_SELECT.forEach((v) => {
            const zones = moment.tz.zonesForCountry(v, true);
            timeZones = timeZones.concat(zones);
        });
        const currentTZ = timeZones.find((v) => v.name === current);
        if (!currentTZ) {
            return [current].concat(timeZones.map((item) => item.name));
        }
        timeZones.sort((a, b) => b.offset - a.offset);
        return timeZones.map((item) => item.name);
    }

    private generateParams(formData): any {
        const defaultParams = {
            group_id: this.groupId,
        };
        const params = new Object();
        Object.values(REPORT_TEMPLATE_PARAMS_KEY).forEach((field) => {
            // add control by params expect date range
            if (formData.hasOwnProperty(field) && EXCLUDE_CREATE_FORM_KEY.indexOf(field) === -1) {
                params[field] = formData[field];
                if (field === this.PARAMS_KEY.postIds) {
                    params[field] = formData[field].map((id) => id.toString().replace('post_', ''));
                } else {
                    params[field] = formData[field];
                }
            }
        });

        if (this.currentKind?.params) {
            if (this.currentKind.params[this.PARAMS_KEY.postIds]) {
                const key = 'moIds';
                const moIds = this.getMoIds().map((item) => item.toString().replace('post_', ''));
                params[key] = moIds;
            }
            if (
                this.currentKind?.params[this.PARAMS_KEY.startDate] &&
                this.currentKind?.params[this.PARAMS_KEY.finishDate]
            ) {
                const start = 'timeBegin';
                const finish = 'timeEnd';
                const timezone = 'timezone';
                params[timezone] = this.tzValue;
                params[start] = moment(this.params.timeBegin).format('YYYY-MM-DD');
                params[finish] = moment(this.params.timeEnd).format('YYYY-MM-DD');
            }
        }

        return { ...defaultParams, ...params };
    }

    private updateIntervalTabs() {
        if (!this.isNeedIntervalTabsUpdate) {
            return;
        }
        const posts = this.getTags();
        const currentTab = this.currentIntervalTab;
        this.intervalTabs = this.currentKind.params[this.PARAMS_KEY.intervals]?.values.map((v) => ({
            title: this.textsTimeNames[v] ?? v,
            type: v,
            tooltip: this.textsIntervalTooltip[v] ?? '',
            isDisabled: v === INTERVAL_SOURCE && posts.length > 1,
        }));
        this.currentIntervalTab = this.intervalTabs.find((v) => v.type === currentTab.type);
    }

    private updateAvailableSubstance() {
        if (this.currentKind.params?.[this.PARAMS_KEY.measure]) {
            const selected = this.listStations.filter((s) => s.selected).map((s) => s.id);
            const posts = this.moObj.filter((v) => selected.indexOf(v.id) >= 0);
            this.availableSubstance = getAllMmt(posts).filter((v) =>
                this.currentKind.params?.[this.PARAMS_KEY.measure].values.includes(v)
            );
            const currentSubstance = this.ReportCreateForm.get('measure')?.value;
            this.ReportCreateForm.controls[this.PARAMS_KEY.measure].updateValueAndValidity();
            if (selected.length && this.availableSubstance.indexOf(currentSubstance[0]) === -1) {
                this.ReportCreateForm.controls[this.PARAMS_KEY.measure].setErrors({
                    unavailable: true,
                    message: this.labelTexts.measure,
                });
            }
        }
    }
}
