import * as moment from 'moment-timezone';
import { LANGUAGE, TEXTS } from '../texts/texts';
import { DURATION_TIME_NAMES, TIME_FORMAT } from '@libs/common/consts/date-format.const';

export class CalendarOutput {
    begin: number;
    end: number;
}
function trimToLen(index: number, len: number) {
    return (str: string, i: number) => (i === index ? str.slice(0, len) : str);
}
export const formatDayMonthYear = (m: moment.Moment) =>
    `${m.format('D')} ${m.format('MMM').substring(0, 3)} ${m.format('YYYY')}`;

export const formatDayMonth = (m: moment.Moment): string =>
    m.format('D') + ' ' + m.format('MMM').substring(0, 3);

export function formatMonthName(dateText: string) {
    return dateText.split(' ').map(trimToLen(1, 3)).join(' ');
}

export function shortDateFormat(dateTime: string, locale: string = LANGUAGE) {
    return formatMonthName(
        moment(dateTime).format(
            {
                ru: `D MMM YYYY, ${TIME_FORMAT}`,
            }[locale] || `MMM Do YYYY, ${TIME_FORMAT}`
        )
    );
}

export function shortDateFormatDay(dateTime: Date, locale: string = LANGUAGE) {
    return formatMonthName(
        moment(dateTime).format(
            {
                ru: 'D MMM YYYY',
            }[locale] || 'MMM D YYYY'
        )
    );
}

export function shortDateFormatWithClosest(dateTime: Date) {
    const { today, yesterday } = TEXTS.COMMON;

    return isToday(dateTime)
        ? today
        : isYesterday(dateTime)
        ? yesterday
        : shortDateFormatDay(dateTime);
}

export function shortDateFormatWithToday(todayDate: Date, dateTime: string) {
    const formatted = moment(dateTime).isSame(todayDate, 'day')
        ? moment(dateTime).format(`[${TEXTS.COMMON.today}], ${TIME_FORMAT}`)
        : shortDateFormat(dateTime);

    return moment(dateTime).diff(moment(), 'days') ? formatMonthName(formatted) : formatted;
}

export function isToday(dateTime: Date) {
    return moment(dateTime).isSame(moment(), 'day');
}

export function isYesterday(dateTime: Date) {
    return isToday(moment(dateTime).add(1, 'day').toDate());
}

export function annotateWithDates<T>(
    getDate: (item: T) => Date,
    formatDate?: (date: Date) => string
) {
    formatDate = formatDate || shortDateFormatDay;

    let prevDateTime = new Date(0);
    let prevLabel = formatDate(prevDateTime);

    return (items: T[]) =>
        items.map((item) => {
            const dateTime = getDate(item);
            const label = formatDate(dateTime);
            const changed = label !== prevLabel;
            const annotation = changed ? label : '';

            if (changed) {
                prevDateTime = dateTime;
                prevLabel = label;
            }

            return {
                item,
                annotation,
            };
        });
}

export function dateRangeText(begin: Date, end: Date, allYear: boolean = false) {
    const beginText = shortDateFormatDay(begin);
    const endText = shortDateFormatDay(end);

    const beginYear = moment(begin).format('YYYY');
    const sameYear = beginYear === moment(end).format('YYYY');
    const separator = '–';

    if (beginText === endText) {
        return beginText;
    } else if (sameYear && !allYear) {
        return [beginText.split(beginYear)[0], separator, endText].join(' ');
    } else {
        return [beginText, separator, endText].join(' ');
    }
}

export function toShortISOString(dateTime: Date) {
    return dateTime.toISOString().replace('.000', '');
}

export function formatDuration(duration: moment.Duration) {
    const abbr = DURATION_TIME_NAMES[LANGUAGE] || DURATION_TIME_NAMES.en;
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const d = [];
    if (years) {
        d.push(`${years} ${abbr.years}`);
    }
    if (months) {
        d.push(`${months} ${abbr.months}`);
    }
    if (days) {
        d.push(`${days} ${abbr.days}`);
    }
    if (hours) {
        d.push(`${hours} ${abbr.hours}`);
    }
    if (minutes) {
        d.push(`${minutes} ${abbr.minutes}`);
    }

    return d.length ? d.join(' ') : `0 ${abbr.minutes}`;
}

export function getIntervalText(time: CalendarOutput, formatter: (time: number) => string): string {
    const textStart = formatter(time?.begin);
    const textEnd = formatter(time?.end);

    return `${textStart} — ${textEnd}`;
}
export function getTZList() {
    const names = [];
    const timeZones = [];
    const allCountries = moment.tz.countries();
    allCountries.forEach((v) => {
        const zones = moment.tz.zonesForCountry(v, true);
        zones.forEach((v) => {
            if (!names.includes(v.name)) {
                names.push(v.name);
                timeZones.push(v);
            }
        });
    });

    timeZones.sort((a, b) => b.offset - a.offset);
    return timeZones;
}
