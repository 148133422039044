export const SLIDES_DATA = [
    {
        id: 'login',
        title: 'Личный кабинет переезжает',
        description: [
            '<span class="onboarding-popup__description-item"><b>В российскую доменную зону</b> по новому адресу <a href="https://mycityair.ru/" class="onboarding-popup__description-link">mycityair.ru</a><br><b>Логин и пароль остались прежними.</b></span><span class="onboarding-popup__description-item">По адресу <a href="https://my.cityair.io/" class="onboarding-popup__description-link">my.cityair.io</a> через некоторое время сервис <b>станет недоступен.</b></span>',
        ],
        imgUrl: 'onboarding/update-chart-10-2022/login-url.png',
    },
    {
        id: 'settings',
        title: 'Добавили вкладку «Системные настройки»',
        description: [
            'Теперь вы можете выбирать единицы измерения веществ в системе: <br>мг/м³, мкг/м³ и доли ПДК',
        ],
        videoUrl: 'onboarding/update-chart-10-2022/add-system-settings.mp4',
        posterUrl: 'onboarding/update-chart-10-2022/poster-add-system-settings.jpg',
    },
    {
        id: 'types',
        title: 'Добавили настройку отображения типа данных в пинах на карте',
        videoUrl: 'onboarding/update-chart-10-2022/display-data-types.mp4',
        posterUrl: 'onboarding/update-chart-10-2022/poster-display-data-types.jpg',
    },
    {
        id: 'chart',
        title: 'Улучшили график',
        description: [
            '<b>Повысили его производительность</b>, теперь он работает быстрее! <br>А ещё <b>добавили выделение веществ</b> в моменты, когда их <b>концентрации превышают ПДК</b>',
        ],
        imgUrl: 'onboarding/update-chart-10-2022/chart.png',
    },
];
