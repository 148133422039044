import { Pipe, PipeTransform } from '@angular/core';
import { formatDayMonth } from '@cityair/config';
import { CalendarOutput } from '@libs/common/utils/date-formats';
import * as moment from 'moment-timezone';
import { getIntervalText } from './get-interval-text.function';

@Pipe({
    name: 'formatDateMobile',
})
export class FormatDateMobilePipe implements PipeTransform {
    transform(time: CalendarOutput): string {
        return getIntervalText(time, (time) => formatDayMonth(moment(time)));
    }
}
