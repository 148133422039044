<div class="mmt-selector">
    <div class="title" *ngIf="title">
        {{title}}
    </div>
    <div class="mmts-wrapper" *ngIf="mmts?.length">
        <div class="mmt-value with-border" *ngIf="showLayerButton" (click)="layerClick()">
            <span *ngIf="activeLayer">{{textLayer.off}}.</span>
            <span *ngIf="!activeLayer">{{textLayer.on}}.</span>
        </div>
        <div class="values-wrapper">
            <div class="label" *ngIf="meteoMmts?.length">{{textSelector.labels.pollutant}}</div>
            <div class="values">
                <div class="mmt-value"
                    *ngFor="let mmt of mmts; let i = index; let l = last"
                    [ngClass]="{
                        'with-border': (AQIs.includes(mmt) && !AQIs.includes(mmts[i + 1])) || (meteoMmts?.length && l),
                        'active': mmt === activeMmt,
                    }"
                    [innerHtml]="textNames[mmt]"
                    (click)="select(mmt)"
                >
                </div>
            </div>
        </div>
        <div class="values-wrapper">
            <div class="label" *ngIf="meteoMmts?.length">{{textSelector.labels.meteo}}</div>
            <div class="values">
                <div class="mmt-value"
                    *ngFor="let mmt of meteoMmts; let i = index"
                    [ngClass]="{ 'active': mmt === activeMmt }"
                    [innerHtml]="textNames[mmt]"
                    (click)="select(mmt)"
                >
                </div>
            </div>
        </div>
    </div>
</div>
