import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import * as moment from 'moment-timezone';
import { TEXTS } from '@libs/common/texts/texts';
import { formatDayMonth } from '@cityair/config';

@Component({
    selector: 'report-date-range',
    templateUrl: './report-date-range.component.html',
    styleUrls: ['./report-date-range.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportDateRangeComponent implements OnChanges {
    @Input() timeBegin: number;
    @Input() timeEnd: number;
    @Input() descriptionText = '';
    @Input() minDate: string;
    @Input() displayMonth = 1;
    @Input() labelText = TEXTS.DOWNLOAD_POPUP.period;
    @Output() timeChange = new EventEmitter<{ begin: number; end: number }>();
    showCalendar = false;
    showAttention = false;

    numberOfMonths = 120;
    handleUpdateDays: (numberOfFutureDays?: number, minDate?: string) => void;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.minDate?.currentValue) {
            const startDate = moment(this.minDate).startOf('month');
            const endDate = moment().endOf('month');
            const diff = endDate.diff(startDate, 'months', true);
            const monthDifference = Math.ceil(diff);
            const increment = this.displayMonth === 2 && monthDifference % 2 === 1 ? 1 : 0;
            this.numberOfMonths = monthDifference + increment;
        }
    }

    public getIntervalText() {
        if (this.timeBegin) {
            return (
                formatDayMonth(moment(this.timeBegin)) +
                ' - ' +
                formatDayMonth(moment(this.timeEnd)) +
                ' ' +
                moment(this.timeEnd).format('YYYY')
            );
        }

        return '';
    }

    public updateTime = (begin, end) => {
        this.timeBegin = begin;
        this.timeEnd = end;
        this.showCalendar = false;

        if (this.timeEnd - this.timeBegin > 3600000 * 24 * 14) {
            this.showAttention = true;
        } else {
            this.showAttention = false;
        }
        this.timeChange.emit({ begin, end });
    };

    public toggleShow() {
        if (this.showCalendar) {
            const calendarConf: { calendarMinDate?: string; numberOfFutureDays?: number } = {
                calendarMinDate: moment(this.minDate).subtract(1, 'days').toISOString(),
                numberOfFutureDays: 0,
            };
            this.handleUpdateDays(calendarConf?.numberOfFutureDays, calendarConf?.calendarMinDate);
        }
    }

    close = () => (this.showCalendar = false);

    updateDays = (fn: () => void) => (this.handleUpdateDays = fn);
}
