import { Pipe, PipeTransform } from '@angular/core';
import { ChartArea, ChartDataset } from 'chart.js';
import { Feature } from '@libs/common/models/feature';
import { measureZones, MeasureZones } from '@libs/common/helpers/measure-zones';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { GASES } from '@libs/common/consts/mmt-with-pdk-sorted.conts';
import {
    DEFAULT_METEO_VALUES_COLOR,
    LINE_DASH_STYLES,
    METEO_VALUES_COLORS,
} from '@libs/shared-ui/components/timeline-panel/constants';
import { isFalseNumber, lightenColor } from '@libs/common/utils/utils';
import * as moment from 'moment-timezone';
import { AQI } from '@libs/common/consts/substance.consts';
import { AqiType } from '@libs/common/enums/aqi.type';
import { getDigitsAfterDot } from '@libs/common/helpers/get-digits-after-dot';
import { TEXTS } from '@libs/common/texts/texts';
import { keyToLabel } from '@libs/shared-ui/components/timeline-panel/chart-timeline/utils/tooltip-helper';
import { Y_SCALE_CONTRIBUTIONS_ID } from '@libs/shared-ui/components/timeline-panel/chart-timeline/chart-timeline.component';
import { CONTRIBUTIONS_COLORS_SOURCES_ORDER } from '@libs/common/consts/demo-impact-groups';
type ChartPoint = {
    x: string;
    y: number;
};
const AQIs = [AQI, ...Object.values(AqiType)];
@Pipe({
    name: 'chartData',
})
export class ChartDataPipe implements PipeTransform {
    transform(
        data: Feature[],
        mmts: string[],
        scheme: MeasureScheme,
        cityNameField
    ): (ChartDataset<'bar', ChartPoint[]> | ChartDataset<'line', ChartPoint[]>)[] {
        const chartData: (
            | ChartDataset<'bar', ChartPoint[]>
            | ChartDataset<'line', ChartPoint[]>
        )[] = [];
        const units = mmts.reduce(
            (acc, mmt) => ({
                ...acc,
                [mmt]: TEXTS.MEASURES_SCHEME[scheme][mmt],
            }),
            {}
        );
        mmts?.forEach((key) => {
            const chartsForKey = data.map((f) => {
                const { timeseries } = f.properties;

                return (
                    (timeseries[key] as number[])
                        ?.map((y, i) => ({
                            y: !isFalseNumber(y)
                                ? Number(y.toFixed(getDigitsAfterDot(scheme, key)))
                                : null,
                            x: timeseries.date[i],
                        }))
                        .filter((point) => !!point.x) || []
                );
            });

            chartsForKey.forEach((chartForKey, i) => {
                const seriesName = data.length > 1 ? data[i].properties[cityNameField] : '';
                const dataset = AQIs.includes(key)
                    ? createBarChartData(
                          withDataPaddings(chartForKey),
                          seriesName,
                          key,
                          units[key] ?? key
                      )
                    : createLineChartData(
                          withDataPaddings(chartForKey),
                          seriesName,
                          key,
                          scheme,
                          units[key] ?? key,
                          i
                      );

                chartData.push(dataset);
            });
        });
        if (data.length === 1 && mmts.length === 1 && data[0]?.properties?.contributions) {
            const contribData = data[0]?.properties?.contributions?.[mmts[0]];
            const contribTimeline = data[0]?.properties?.timelineContributions?.[mmts[0]];
            const date = data[0]?.properties?.contributions.date;
            if (contribData && date) {
                const timeline = data[0]?.properties?.timeseries[mmts[0]];
                const sources = data[0]?.properties?.contributionsDetails?.sources;
                const order = data[0]?.properties?.contributionsDetails?.order;
                const sourceKeys = Object.keys(contribData);
                sourceKeys.forEach((key, k) => {
                    const colorIndex = order?.findIndex((v) => v === sources[key]?.id);
                    const color = CONTRIBUTIONS_COLORS_SOURCES_ORDER[colorIndex] ?? '#84e39e';
                    const dataChart = contribData[key].map((v, index) => ({
                        y:
                            data[0]?.properties?.obj === 'control_point' && timeline[index]
                                ? Math.round(v)
                                : contribTimeline?.[index] !== null
                                ? Math.round(v)
                                : null,
                        x: date[index] as unknown as string,
                    })) as unknown as ChartPoint[];
                    const dataset = createBarChartStakedData(
                        withDataPaddings(dataChart),
                        sources[key]?.name,
                        mmts[0],
                        color
                    );
                    chartData.push(dataset);
                });
                if (contribTimeline?.length) {
                    const data = contribTimeline.map((v, index) => ({
                        y: Math.round(v),
                        x: date[index] as unknown as string,
                    })) as unknown as ChartPoint[];
                    const dataset = createLineContributionChartData(
                        withDataPaddings(data),
                        mmts[0],
                        units[mmts[0]]
                    );

                    chartData.push(dataset);
                }
            }
        }

        return chartData;
    }
}

function getGradient(
    ctx: CanvasRenderingContext2D,
    chartArea: ChartArea,
    mmt: string,
    pinsZones: MeasureZones,
    data: number[]
) {
    const result = [...data];
    const min = Math.min(...result);
    const max = Math.max(...result);
    if (pinsZones) {
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        const countEndPoints = pinsZones.getIndex(mmt, max);
        gradient.addColorStop(0, pinsZones.getColor(min, mmt));
        for (let k = 1; k <= countEndPoints - 2; k++) {
            gradient.addColorStop(k / countEndPoints, pinsZones.getColorByIndex(mmt, k + 1));
        }
        gradient.addColorStop(1, pinsZones.getColor(max, mmt));
        return gradient;
    }
}

function createLineChartData(
    data: ChartPoint[],
    seriesName: string,
    mmt: string,
    measureScheme: MeasureScheme,
    axisId: string,
    i: number
): ChartDataset<'line', ChartPoint[]> {
    return {
        label: keyToLabel(seriesName, mmt),
        type: 'line',
        data,
        borderColor: function (context) {
            measureZones.setScheme(measureScheme);
            if (!GASES.includes(mmt) && measureZones.getZone(mmt)) {
                const chart = context.chart;
                const valuesY = data.map((v) => v.y);
                const { ctx, chartArea } = chart;

                // This case happens on initial chart load
                if (!chartArea) {
                    return null;
                }
                return getGradient(ctx, chartArea, mmt, measureZones, valuesY);
            } else {
                return METEO_VALUES_COLORS[mmt] || DEFAULT_METEO_VALUES_COLOR;
            }
        },
        borderDash: LINE_DASH_STYLES[i],
        order: 1,
        yAxisID: axisId,
    };
}
function createLineContributionChartData(
    data: ChartPoint[],
    mmt: string,
    axisId: string
): ChartDataset<'line', ChartPoint[]> {
    return {
        label: keyToLabel('Расчет', mmt),
        type: 'line',
        data,
        borderColor: '#888',
        borderDash: [8, 3],
        order: 1,
        yAxisID: axisId,
    };
}
function createBarChartStakedData(
    data: ChartPoint[],
    sourceName: string,
    mmt: string,
    color: string
): ChartDataset<'bar', ChartPoint[]> {
    return {
        label: keyToLabel(sourceName, mmt),
        data,
        type: 'bar',
        backgroundColor: color,
        borderColor: color,
        hoverBackgroundColor: lightenColor(color),
        borderRadius: 2,
        barThickness: 'flex',
        maxBarThickness: 30,
        order: 2,
        yAxisID: Y_SCALE_CONTRIBUTIONS_ID,
    };
}
function createBarChartData(
    data: ChartPoint[],
    seriesName: string,
    key: string,
    axisId: string
): ChartDataset<'bar', ChartPoint[]> {
    const backgroundColor = data.map((p) => measureZones.getColor(p.y, key));
    return {
        label: keyToLabel(seriesName, key),
        type: 'bar',
        borderRadius: 2,
        barThickness: 'flex',
        data,
        backgroundColor,
        hoverBackgroundColor: backgroundColor.map((c) => lightenColor(c)),
        order: 2,
        yAxisID: axisId,
    };
}

function withDataPaddings(data: ChartPoint[]) {
    if (!data?.length) {
        return [];
    }

    const first = moment(data[0].x);
    const last = moment(data[data.length - 1].x);
    let timeStep = (last.valueOf() - first.valueOf()) / (data.length - 1);
    const addDelta = (date: moment.Moment, d: number) => date.add(d, 'milliseconds').toISOString();
    if (data.length === 1) {
        return [...data];
    } else if (data.length < 8) {
        timeStep = (last.valueOf() - first.valueOf()) / (2 * data.length);
    } else if (data.length >= 8 && data.length < 25) {
        timeStep = (last.valueOf() - first.valueOf()) / (1.7 * (data.length + 1));
    }

    return [
        {
            x: addDelta(first, -timeStep),
            y: null,
        },
        ...data,
        {
            x: addDelta(last, timeStep),
            y: null,
        },
    ];
}
