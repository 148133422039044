import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { SlideData } from '@cityair/components/onboarding/onboarding.type';
import { Router } from '@angular/router';

@Component({
    selector: 'cityair-map-style-modal',
    templateUrl: './map-styles-modal.component.html',
    styleUrls: ['./map-styles-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapStylesModalComponent {
    @Input() slides: SlideData[];
    @Output() closePopup = new EventEmitter<boolean>();

    markedAsSeen = false;
    currentSlideIndex = 0;

    constructor(private router: Router) {}

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.close();
    }

    close() {
        this.closePopup.emit(this.markedAsSeen);
    }

    nextSlide() {
        if (this.currentSlideIndex < this.slides.length - 1) {
            this.currentSlideIndex++;
        }
    }

    selectSlide(item: SlideData) {
        this.currentSlideIndex = this.slides.indexOf(item);
    }

    isLastSlide() {
        return this.currentSlideIndex >= this.slides.length - 1;
    }

    try() {
        this.closePopup.emit(true);
        this.router.navigate([`/`]);
    }
}
