import { createReducer, on } from '@ngrx/store';
import { Station } from '../services/station/models';
import * as plumesActions from './actions';
import { RunPlume, Source } from '../services/run/models';
import { ControlPoint, ControlPointsResponse } from '../services/control-point/models';
import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { AIRTRACER } from '@cityair/modules/plumes/constants';
import { Feature } from '@libs/shared-ui/components/timeline-panel/models/core';
import { SO2 } from '@libs/common/consts/substance.consts';
import { sortMeasurements } from '@libs/common/utils/utils';
import { DataQualityTimeline } from '@libs/common/models/dataQuality';

export interface DateRange {
    start: string;
    end: string;
}
export interface EvaluationTime {
    begin: number;
    end: number;
}
export const sourcesAdapter: EntityAdapter<Source> = createEntityAdapter<Source>();
export interface PlumesState {
    currentMmt: string;
    isActiveModule: boolean;
    showLayerOnMap: boolean;
    showFullChartDataStation: boolean;
    activeStation: Station | null;
    activeRun: RunPlume;
    activeRunDates: RunPlume;
    activeControlPoint: ControlPoint;
    dates: string[];
    dateRangeRuns: DateRange;
    evaluationTime: EvaluationTime;
    currentTimeIndex: number;
    isNeedDatesUpdate: boolean;
    runsLoadError: boolean;
    disabledMapNavigation: boolean;
    controlPointReports: ControlPoint[];
    isLoadingRun: boolean;
    controlPointsError: any;
    runConfigError: any;
    editControlPoint: ControlPoint;
    coordinates: { lat: number; lon: number };
    isLoadingChart: boolean;
    currentHeight: number;
    speciesList: string[];
    isLoadingControlPoint: boolean;
    chartData: Feature[];
    isAvailableWindLayer: boolean;
    showWindOnMap: boolean;
    disableWindButton: boolean;
    qualityDataTimeline: DataQualityTimeline[];
    expandControlPoint: string;
    sourceOrderForColor: number[];
    sourcesSnapshots: EntityState<Source>;
    displayPinsOnMap: boolean;
    filterForRunsByConfig: number;
    isLoadingContributionData: boolean;
    errorContributionData: boolean;
}

export const initialPlumesState: PlumesState = {
    currentMmt: null,
    isActiveModule: false,
    showLayerOnMap: false,
    showFullChartDataStation: false,
    activeStation: null,
    activeRun: null,
    activeRunDates: null,
    activeControlPoint: null,
    dates: [],
    dateRangeRuns: null,
    evaluationTime: null,
    currentTimeIndex: null,
    isNeedDatesUpdate: true,
    runsLoadError: false,
    disabledMapNavigation: false,
    controlPointReports: [],
    isLoadingRun: false,
    controlPointsError: null,
    runConfigError: null,
    editControlPoint: null,
    coordinates: null,
    isLoadingChart: false,
    currentHeight: null,
    speciesList: null,
    isLoadingControlPoint: false,
    chartData: [],
    isAvailableWindLayer: false,
    showWindOnMap: false,
    disableWindButton: false,
    qualityDataTimeline: [],
    expandControlPoint: null,
    sourceOrderForColor: [],
    sourcesSnapshots: sourcesAdapter.getInitialState(),
    displayPinsOnMap: true,
    filterForRunsByConfig: null,
    isLoadingContributionData: false,
    errorContributionData: false,
};

export const plumesReducers = createReducer(
    initialPlumesState,
    on(plumesActions.toggleModule, (state: PlumesState, props) => ({
        ...state,
        isActiveModule: props.payload,
    })),
    on(plumesActions.toggleLayerOnMap, (state: PlumesState, props) => ({
        ...state,
        showLayerOnMap: props.payload,
    })),
    on(plumesActions.setPlumesMeasurement, (state: PlumesState, props) => ({
        ...state,
        currentMmt: props.payload,
    })),
    on(plumesActions.setActiveStation, (state: PlumesState, props) => {
        if (props.payload) {
            return {
                ...state,
                activeStation: props.payload,
                activeControlPoint: null,
            };
        } else {
            return {
                ...state,
                activeStation: props.payload,
            };
        }
    }),
    on(plumesActions.updateTimeIndex, (state: PlumesState, props) => ({
        ...state,
        currentTimeIndex: props.payload,
    })),
    on(plumesActions.updateDateRange, (state: PlumesState, props) => ({
        ...state,
        dateRange: props,
    })),
    on(plumesActions.datesUpdate, (state: PlumesState, props) => {
        if (props.payload.length) {
            const dates = [...props.payload];
            const oldCurrentData = state.dates[state.currentTimeIndex]
                ? state.dates[state.currentTimeIndex]
                : '';
            let currentIndex = dates.indexOf(oldCurrentData);
            if (currentIndex == state.dates.length - 1) {
                currentIndex = -1;
            }
            // if current data update index
            const index = currentIndex == -1 ? 0 : currentIndex;

            return {
                ...state,
                dates: dates,
                currentTimeIndex: index,
                activeRunDates: { ...state.activeRun },
                isNeedDatesUpdate: false,
            };
        }

        return state;
    }),
    on(plumesActions.setRunsLoadError, (state, props) => ({
        ...state,
        runsLoadError: props.payload,
    })),
    on(plumesActions.setActiveRun, (state: PlumesState, props) => {
        const mmt = checkCurrentValueForUpdate(
            state.currentMmt,
            props.payload.species_list,
            props.payload.default_species
        );
        return {
            ...state,
            activeRun: props.payload,
            currentMmt: mmt === AIRTRACER ? SO2 : mmt,
            currentHeight: +checkCurrentValueForUpdate(state.currentHeight, props.payload.heights),
            speciesList: correctSpeciesList(props.payload.species_list),
            isLoadingRun: props.payload ? true : false,
        };
    }),
    on(plumesActions.disabledMapNavigation, (state: PlumesState, props) => ({
        ...state,
        disabledMapNavigation: props.payload,
    })),
    on(plumesActions.setRunLoading, (state: PlumesState, props) => ({
        ...state,
        isLoadingRun: props.payload,
    })),
    on(plumesActions.setControlPointData, (state: PlumesState, props) => {
        const data = getControlPointsWithTimeseries(props.payload, props.controlPoints);
        return {
            ...state,
            controlPointReports: data,
            isLoadingRun: false,
            isLoadingControlPoint: false,
        };
    }),
    on(plumesActions.setControlPointError, (state: PlumesState, props) => ({
        ...state,
        controlPointsError: props.payload,
    })),
    on(plumesActions.updateDateRangeRun, (state: PlumesState, props) => ({
        ...state,
        dateRangeRuns: props.payload,
    })),
    on(plumesActions.setActivePlumesControlPoint, (state: PlumesState, props) => {
        if (props.payload) {
            return {
                ...state,
                activeControlPoint: props.payload,
                activeStation: null,
            };
        } else {
            return {
                ...state,
                activeControlPoint: props.payload,
            };
        }
    }),
    on(plumesActions.setRunConfigUpdateError, (state: PlumesState, props) => ({
        ...state,
        runConfigError: props.payload,
    })),
    on(plumesActions.setRunConfigByIdError, (state: PlumesState, props) => ({
        ...state,
        runConfigError: props.payload,
    })),
    on(plumesActions.setEditControlPoint, (state: PlumesState, props) => ({
        ...state,
        editControlPoint: props.payload,
    })),
    on(plumesActions.setNewControlPoint, (state: PlumesState, props) => {
        let result = [...state.controlPointReports];
        if (props.payload) {
            result.push(props.payload);
        } else {
            result = state.controlPointReports.filter((point) => point.id);
        }
        return {
            ...state,
            controlPointReports: result,
        };
    }),
    on(plumesActions.setEditControlPoint, (state: PlumesState, props) => ({
        ...state,
        editControlPoint: props.payload,
    })),
    on(plumesActions.setCoordinates, (state: PlumesState, props) => ({
        ...state,
        coordinates: props.payload,
    })),
    on(plumesActions.updateControlPoint, (state: PlumesState, props) => {
        let result = [...state.controlPointReports];
        if (props.payload) {
            if (props.field === 'hash' && props.payload.id) {
                const data = Object.assign({}, { ...props.payload }, { hash: Date.now() });
                result = result.map((point) => (point.id === props.payload.id ? data : point));
            } else if (props.field === 'name') {
                result = result.map((point) =>
                    point.id === props.payload.id ? props.payload : point
                );
            }
        }

        return {
            ...state,
            controlPointReports: result,
        };
    }),
    on(plumesActions.setChartLoading, (state: PlumesState, props) => ({
        ...state,
        isLoadingChart: props.payload,
    })),
    on(plumesActions.setHeight, (state: PlumesState, props) => ({
        ...state,
        currentHeight: props.payload,
    })),
    on(plumesActions.changeCurrentMmt, (state: PlumesState, props) => ({
        ...state,
        currentMmt: props.payload,
        isLoadingRun: true,
    })),
    on(plumesActions.setControlPointLoading, (state: PlumesState, props) => ({
        ...state,
        isLoadingControlPoint: props.payload,
    })),
    on(plumesActions.setChartData, (state: PlumesState, props) => ({
        ...state,
        chartData: props.payload,
    })),
    on(plumesActions.setConfigForStationChart, (state: PlumesState, props) => ({
        ...state,
        showFullChartDataStation: props.payload,
    })),
    on(plumesActions.toggleWindLayer, (state: PlumesState, props) => ({
        ...state,
        showWindOnMap: props.payload,
    })),
    on(plumesActions.isDisabledWindButton, (state: PlumesState, props) => ({
        ...state,
        disableWindButton: props.payload,
    })),
    on(plumesActions.setExpandControlPoint, (state: PlumesState, props) => ({
        ...state,
        expandControlPoint: props.payload,
    })),
    on(plumesActions.setSourcesSnapShot, (state: PlumesState, { payload }) => {
        const configIds = [];
        const result = [];
        const resultIds = [];
        payload.forEach((run) => {
            if (!configIds.includes(run.config)) {
                configIds.push(run.config);
                run.sources_snapshot?.forEach((source) => {
                    if (!resultIds.includes(source.id)) {
                        resultIds.push(source.id);
                        result.push(source);
                    }
                });
            }
        });

        const sourcesSnapshots = sourcesAdapter.setMany(result, state.sourcesSnapshots);
        return {
            ...state,
            sourcesSnapshots,
        };
    }),
    on(plumesActions.toggleDisplayPostOnMap, (state: PlumesState, props) => ({
        ...state,
        displayPinsOnMap: props.payload,
    })),
    on(plumesActions.setFilterForRuns, (state: PlumesState, props) => ({
        ...state,
        filterForRunsByConfig: props.payload,
    })),
    on(plumesActions.setSourcesOrder, (state: PlumesState, props) => ({
        ...state,
        sourceOrderForColor: props.payload,
    })),
    on(plumesActions.setContributionDataToActiveStation, (state: PlumesState, { payload }) => {
        let activeStation = state.activeStation;
        if (activeStation) {
            activeStation = { ...activeStation, dataContribution: payload };
        }
        return {
            ...state,
            activeStation,
        };
    }),
    on(plumesActions.setLoadingContributionData, (state: PlumesState, { payload }) => ({
        ...state,
        isLoadingContributionData: payload,
    })),
    on(plumesActions.setLoadingContributionData, (state: PlumesState, { payload }) => ({
        ...state,
        errorContributionData: payload,
    }))
);
function getControlPointsWithTimeseries(
    response: ControlPointsResponse,
    controlPoints: Dictionary<ControlPoint>
) {
    if (!response) return [];
    const dates = response.meta.dates;
    const result = [];
    response.control_points_measurements.forEach((item) => {
        const point = controlPoints[item.control_point];
        if (point) {
            result.push({
                ...point,
                timeline: Object.assign({}, { date: dates }, item.timeseries),
                contributions: Object.assign({}, { date: dates }, item.contributions),
            });
        }
    });

    return result;
}
function checkCurrentValueForUpdate(current, list, defaultValue?): string {
    let newValue = current;
    if (current) {
        if (list.indexOf(current) === -1) {
            newValue = defaultValue ?? list[0];
        }
    } else {
        newValue = defaultValue ?? list[0];
    }
    return newValue;
}
function correctSpeciesList(list: string[]): string[] {
    if (!list) return;
    const result = [...list];
    const index = list.indexOf(AIRTRACER);
    if (index >= 0) {
        result.splice(index, 1);
    }
    return result.sort(sortMeasurements);
}
