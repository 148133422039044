import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpEvent,
    HttpStatusCode,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { addAlert } from '@cityair/modules/core/store/actions';
import { Store } from '@ngrx/store';
import { GROUP_ID, WindowGlobalVars } from '@cityair/namespace';
import { environment } from 'environments/environment';

declare const window: WindowGlobalVars;
const NO_LOG_STATUS = [HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden];
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if (!navigator.onLine) {
                    this.store.dispatch(
                        addAlert({
                            id: new Date().valueOf(),
                            messageKey: 'internet_offline',
                            positionX: 'center',
                            positionY: 'top',
                            iconClass: 'warning',
                            size: 'md',
                        })
                    );
                }
                window.sentryAddBreadcrumb?.({
                    data: {
                        online: window.navigator?.onLine,
                        url: req?.urlWithParams ?? req?.url,
                        user: window.JS_CP_LOGIN,
                        group: localStorage?.getItem(GROUP_ID),
                    },
                    timestamp: new Date().getTime(),
                });
                const checkError = this.checkErrorForLog(err);
                if (window.sentryCaptureException && checkError) {
                    if (err?.error?.meta?.error) {
                        const details = `Query Error: details - ${err?.error?.meta?.error?.internal_error_code} - ${err?.error?.meta?.error?.message}`;
                        err.message = details;
                    }
                    window.sentryCaptureException(err.message);
                }

                return throwError(err);
            })
        );
    }

    checkErrorForLog(error) {
        return NO_LOG_STATUS.indexOf(error?.status) >= 0 ||
            error?.url.indexOf('CreateTokenByLogin') >= 0 ||
            error?.url.indexOf('SendPasswordResetCodeByEmail') ||
            (error?.url.indexOf(environment.vanga_api_url) >= 0 &&
                (error?.status === HttpStatusCode.Unauthorized ||
                    error?.status === HttpStatusCode.NotFound))
            ? false
            : true;
    }
}
