<ng-template #popup>
    <div class="onboarding-popup-container scroll" [ngSwitch]="featureName">
        <cs-slides-onboarding-modal
            *ngSwitchCase="'facelifting-12-2021'"
            [slides]="slides"
            (closePopup)="closePopup($event)"
        ></cs-slides-onboarding-modal>
        <cs-simple-onboarding-modal
            *ngSwitchCase="'instant-aqi-03-2022'"
            [slides]="slides"
            (closePopup)="closePopup($event)"
        ></cs-simple-onboarding-modal>
        <cs-new-map-onboarding-modal
            *ngSwitchCase="'new-map-style-07-2022'"
            [slides]="slides"
            (closePopup)="closePopup($event)"
        ></cs-new-map-onboarding-modal>
        <slides-update-chart-modal
            *ngSwitchCase="'update-chart-10-2022'"
            [slides]="slides"
            (closePopup)="closePopup($event)"
        ></slides-update-chart-modal>
        <added-reports-modal
            *ngSwitchCase="'added-reports-02-2023'"
            [slides]="slides"
            (closePopup)="closePopup($event)"
        ></added-reports-modal>
        <cityair-analysis-modal
            *ngSwitchCase="'analysis-11-2023'"
            [slides]="slides"
            (closePopup)="closePopup($event)"
        ></cityair-analysis-modal>
        <cityair-map-style-modal
            *ngSwitchCase="'map-style-07-2024'"
            [slides]="slides"
            (closePopup)="closePopup($event)"
        ></cityair-map-style-modal>
    </div>
</ng-template>
