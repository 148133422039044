import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { openInstantAqiFaq } from '@cityair/modules/core/store/actions';

import type { SlideData } from '../../onboarding.type';

@Component({
    selector: 'cs-simple-onboarding-modal',
    templateUrl: 'simple-onboarding-modal.component.html',
    styleUrls: ['simple-onboarding-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleOnboardingModalComponent {
    @Input() slides: SlideData[];

    @Output() closePopup = new EventEmitter<boolean>();

    markedAsSeen = false;

    currentSlideIndex = 0;

    constructor(private store: Store) {}

    close() {
        this.closePopup.emit(this.markedAsSeen);
    }

    openInstantAqiFaq(e: Event) {
        e.preventDefault();
        this.store.dispatch(openInstantAqiFaq());
    }
}
