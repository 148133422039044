import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ControlPointForecast, DateRange, ForecastConfig, Station } from '../models';
import { Feature } from '@libs/shared-ui/components/timeline-panel/models/core';

export enum ForecastActions {
    updateDateRange = '[ForecastAction] updateDateRange',
    toggleModule = '[ForecastAction] toggleModule',
    toggleLayerOnMap = '[ForecastAction] toggleLayerOnMap',
    setForecastMeasurement = '[ForecastAction] setForecastMeasurement',
    onStationLoadFailed = '[ForecastAction] onStationLoadFailed',
    setToken = '[ForecastAction] setToken',
    onUpdateControlPoint = '[ForecastAction] onUpdateControlPoint',
    addControlPointError = '[ForecastAction] addControlPointError',
    setActivePoint = '[ForecastAction] setActivePoint',
    setAuthError = '[ForecastAction] setAuthError',
    setErrorLoadControlList = '[ForecastAction] setErrorLoadControlList',
    updateTimeIndex = '[ForecastAction] updateTimeIndex',
    datesUpdate = '[ForecastAction] datesUpdate',
    setActiveStation = '[ForecastAction] setActiveStation',
    updateAllData = '[ForecastAction] updateAllData',
    updateStation = '[ForecastAction] updateStation',
    updateForecastConfig = '[ForecastAction] updateForecastConfig',
    setNewControlPoint = '[Forecast Action] set new control point',
    setCoordinates = '[Forecast Action] setCoordinates',
    setEditControlPointId = '[Forecast Action] edit Control Point Id',
    setChartData = '[Forecast Action] set chart data',
    setCurrentConfig = '[Forecast Action] set current config',
}
export const updateDateRange = createAction(ForecastActions.updateDateRange, props<DateRange>());
export const toggleModule = createAction(
    ForecastActions.toggleModule,
    props<{ payload: boolean }>()
);
export const toggleLayerOnMap = createAction(
    ForecastActions.toggleLayerOnMap,
    props<{ payload: boolean }>()
);
export const setForecastMeasurement = createAction(
    ForecastActions.setForecastMeasurement,
    props<{ payload: string }>()
);
export const onStationLoadFailed = createAction(
    ForecastActions.onStationLoadFailed,
    props<{ payload: any }>()
);
export const setToken = createAction(ForecastActions.setToken, props<{ payload: boolean }>());
export const onUpdateControlPoint = createAction(
    ForecastActions.onUpdateControlPoint,
    props<{ payload: boolean }>()
);
export const addControlPointError = createAction(
    ForecastActions.addControlPointError,
    props<{ payload: HttpErrorResponse }>()
);
export const setActivePoint = createAction(
    ForecastActions.setActivePoint,
    props<{ payload: ControlPointForecast }>()
);
export const setAuthError = createAction(
    ForecastActions.setAuthError,
    props<{ payload: boolean }>()
);
export const setErrorLoadControlList = createAction(
    ForecastActions.setErrorLoadControlList,
    props<{ payload: any }>()
);
export const updateTimeIndex = createAction(
    ForecastActions.updateTimeIndex,
    props<{ payload: number }>()
);
export const datesUpdate = createAction(
    ForecastActions.datesUpdate,
    props<{ payload: string[] }>()
);
export const setActiveStation = createAction(
    ForecastActions.setActiveStation,
    props<{ payload: Station }>()
);
export const updateAllData = createAction(ForecastActions.updateAllData);
export const updateStation = createAction(ForecastActions.updateStation);
export const updateForecastConfig = createAction(ForecastActions.updateForecastConfig);
export const setNewControlPoint = createAction(
    ForecastActions.setNewControlPoint,
    props<{ payload: ControlPointForecast }>()
);
export const setCoordinates = createAction(
    ForecastActions.setCoordinates,
    props<{ payload: { lat: number; lon: number } }>()
);
export const setEditControlPointId = createAction(
    ForecastActions.setEditControlPointId,
    props<{ id: string }>()
);
export const setChartData = createAction(
    ForecastActions.setChartData,
    props<{ payload: Feature[] }>()
);
export const setCurrentConfig = createAction(
    ForecastActions.setCurrentConfig,
    props<{ payload: { config: ForecastConfig; dateRange: DateRange } }>()
);
