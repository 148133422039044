<div class="map-control-wrapper"
    [ngClass]="{
        'no-open-sidebar': !isOpenSidebar,
        'wrap-container': checkWrap
    }"
>
    <div class="map-control">
        <div class="period-wrapper">
            <div class="title" #titleWrapper>{{title}}</div>
            <div #periodContentWrapper>
                <ng-content select="[map-control-period]"></ng-content>
            </div>
        </div>
        <div class="mmts-wrapper" #mmtsWrapper>
            <ng-content select="[map-control-mmts]"></ng-content>
        </div>
    </div>
    <div #switchButton class="switch-button">
        <ng-content select="[switch-selector]"></ng-content>
    </div>
</div>
