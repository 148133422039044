export const SLIDES_DATA = [
    {
        subtitle: 'Что нового?',
        title: 'Модуль «Отчёты»',
        description: [
            'Теперь скачивание данных с постов доступно в модуле «Отчёты» в левом меню. Отчёты будут пополняться стандартными формами, а также предусматривают возможность создания персональных форм отчетов по запросу.',
        ],
        imgUrl: 'onboarding/added-reports-02-2023/report.png',
        button: 'Попробовать',
        hidePopup: 'Больше не показывать',
    },
];
