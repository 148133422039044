import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { StorageService } from '@cityair/modules/login/services/auth/storage.service';
import type { SlideData } from './onboarding.type';
import * as slidesData from './onboardings';
import { getAssetPath } from '@cityair/config';
import { mapStylesSlides } from './onboardings';

const ONBOARDING_KEY = 'onboarding_done';

const FEATURES: Record<string, { key: string; slides: SlideData[] }> = {
    'facelifting-12-2021': {
        key: 'facelifting-12-2021',
        slides: slidesData.faceliftingSlides,
    },
    'instant-aqi-03-2022': {
        key: 'instant-aqi-03-2022',
        slides: slidesData.instantAqiSlides,
    },
    'new-map-style-07-2022': {
        key: 'new-map-style-07-2022',
        slides: slidesData.newMapStyleSlides,
    },
    'update-chart-10-2022': {
        key: 'update-chart-10-2022',
        slides: slidesData.updateChartSlides,
    },
    'added-reports-02-2023': {
        key: 'added-reports-02-2023',
        slides: slidesData.addedReportsSlides,
    },
    'analysis-11-2023': {
        key: 'analysis-11-2023',
        slides: slidesData.analysisSlides,
    },
    'map-style-07-2024': {
        key: 'map-style-07-2024',
        slides: slidesData.mapStylesSlides,
    },
};

@Component({
    selector: 'cs-onboarding',
    templateUrl: 'onboarding.component.html',
    styleUrls: ['onboarding.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingComponent implements OnInit {
    @Input() featureName: string;

    @ViewChild('popup', { static: true }) popup: TemplateRef<HTMLDivElement>;

    slides: SlideData[];

    constructor(
        private popupProvider: OffPanelPopupService,
        private storageService: StorageService
    ) {}

    ngOnInit() {
        const onboardingDone = this.storageService.get(ONBOARDING_KEY) === this.featureName;
        const onboardingStashed = sessionStorage.getItem(ONBOARDING_KEY) === this.featureName;

        if (!onboardingDone && !onboardingStashed) {
            this.slides =
                FEATURES[this.featureName]?.slides.map((v) => ({
                    ...v,
                    imgUrl: getAssetPath(v.imgUrl),
                    imgUrlCompare: v.imgUrlCompare ? getAssetPath(v.imgUrlCompare) : null,
                    videoUrl: getAssetPath(v.videoUrl),
                    posterUrl: getAssetPath(v.posterUrl),
                })) || [];

            this.showPopup();
        }
    }

    showPopup() {
        this.popupProvider.setTemplate(this.popup);
    }

    closePopup(markedAsSeen: boolean) {
        if (markedAsSeen) {
            this.storageService.set(ONBOARDING_KEY, this.featureName);
        }

        this.popupProvider.clear();
    }
}
