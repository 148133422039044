import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { ContributionsSeries } from '@libs/common/models/feature';

export interface ControlPoint {
    name: string;
    lat: number;
    lon: number;
    id?: string;
    group_id?: string;
    obj?: string;
    timeline?: Timeline;
    contributions?: ContributionsSeries;
}

export interface Timeseries {
    RH?: number[];
    PM25?: number[];
    PM10?: number[];
    CO?: number[];
    NO2?: number[];
    SO2?: number[];
    O3?: number[];
    H2S?: number[];
    NH3?: number[];
    SUM?: number[];
}
export interface ContributionsValue {
    [key: string]: number;
}
export interface Contributions {
    RH?: ContributionsValue[];
    PM25?: ContributionsValue[];
    PM10?: ContributionsValue[];
    CO?: ContributionsValue[];
    NO2?: ContributionsValue[];
    SO2?: ContributionsValue[];
    O3?: ContributionsValue[];
    H2S?: ContributionsValue[];
    NH3?: ContributionsValue[];
    SUM?: ContributionsValue[];
}
export interface ControlPointMeasurements {
    control_point: number;
    obj: string;
    timeseries: Timeseries;
    contributions: Contributions;
}
export interface ControlPointsResponse {
    meta: {
        dates: string[];
        height?: number;
        species_list: string[];
        start: string;
        end: string;
        sources: number[];
    };
    control_points_measurements: ControlPointMeasurements[];
}
export interface Timeline {
    date: string[];
    T?: number[];
    P?: number[];
    RH?: number[];
    PM25?: number[];
    PM10?: number[];
    CO?: number[];
    NO2?: number[];
    SO2?: number[];
    O3?: number[];
    H2S?: number[];
    NH3?: number[];
    SUM?: number[];
    obj: string;
    id: number;
}
type VangaSchemeType = 'default' | 'mpc_instantaneous' | 'c_mmhg_mg';
export function getVangaScheme(scheme: MeasureScheme): VangaSchemeType {
    switch (scheme) {
        case MeasureScheme.mpc:
            return 'mpc_instantaneous';
            break;
        case MeasureScheme.c_mmhg_mg:
            return 'c_mmhg_mg';
            break;
        default:
            return 'default';
            break;
    }
}
