<ng-container *ngIf="isOpenLayers else mapboxBorderTemp">
    <ng-container *ngIf="bbox || coordinates">
        <shared-ui-ol-layer
            [features]="feature"
        ></shared-ui-ol-layer>
    </ng-container>
    <ng-container *ngIf="centerCoordinates as center">
        <shared-ui-ol-overlay [position]="[center.lng, northLat]" [positioning]="'bottom-center'">
            <boundary-marker>{{ TEXTS.MODELS.border }}</boundary-marker>
        </shared-ui-ol-overlay>
        <shared-ui-ol-overlay [position]="[center.lng, southLat]" [positioning]="'top-center'">
            <boundary-marker>{{ TEXTS.MODELS.border }}</boundary-marker>
        </shared-ui-ol-overlay>
    </ng-container>
</ng-container>
<ng-template #mapboxBorderTemp>
    <ng-container *ngIf="bbox || coordinates">
        <mgl-geojson-source
            [id]="'domain-boundary-data' + dataId"
            [data]="feature"
        ></mgl-geojson-source>
        <mgl-layer
            [id]="'domain-boundary-polyline' + dataId"
            type="line"
            [source]="'domain-boundary-data' + dataId"
            [paint]="{
                'line-color': ['get', 'color'],
                'line-width': ['get', 'width'],
                'line-dasharray': ['get', 'dasharray']
            }"
        ></mgl-layer>
        <ng-container *ngIf="centerCoordinates as center">
            <mgl-marker [lngLat]="[center.lng, northLat]">
                <boundary-marker shift="north">{{ TEXTS.MODELS.border }}</boundary-marker>
            </mgl-marker>
            <mgl-marker [lngLat]="[center.lng, southLat]">
                <boundary-marker shift="south">{{ TEXTS.MODELS.border }}</boundary-marker>
            </mgl-marker>
        </ng-container>
    </ng-container>
</ng-template>
