import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { KebabMenuModule } from '@cityair/libs/shared/components/kebab-menu/kebab-menu.module';
import { OverlayModule } from '@cityair/libs/shared/modules/overlay/overlay.module';

import { OnboardingModule } from './onboarding/onboarding.module';
import { PopupsModule } from './popups/popups.module';
import { MobileHeaderModule } from './mobile-header/mobile-header.module';
import { StaticTagsModule } from '@libs/shared-ui/components/static-tags/static-tags.module';
import { GroupListItemModule } from './group-list-item/group-list-item.module';
import { GroupListItemMobileModule } from './group-list-item-mobile/group-list-item-m.module';
import { InstantAqiLegendModule } from './instant-aqi-legend/instant-aqi-legend.module';
import { InstantAqiFaqModule } from './instant-aqi-faq/instant-aqi-faq.module';
import { ActiveInactiveTagModule } from './active-inactive-tag/active-inactive-tag.module';
import { SubstanceTagModule } from '@libs/shared-ui/components/substance-tag/substance-tag.module';

import { AqiLegend } from './aqi-legend/aqi-legend.component';
import { Logout } from './logout/logout.component';
import { ShowModelBtn } from './model-panel/model-panel.component';
import { LayersButtonModelsComponent } from './layers-button-models/layers-button-models.component';
import { LayersButtonPlumesComponent } from './layers-button-plumes/layers-button-plumes.component';
import { CsTopRightElements } from './top-right-elements/top-right-elements.component';
import { RunSummaryComponent } from './run-summary/run-summary.component';
import { DownloadPopupsWrapperComponent } from './download-popups-wrapper/download-popups-wrapper.component';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { TopRightElementsMobile } from './top-right-elements-m/top-right-elements-m.component';
import { AqiIndicatorModule } from './aqi-indicator/aqi-indicator.module';
import { CapiAqiLegendComponent } from './capi-aqi-legend/capi-aqi-legend.component';
import { InfoMessageComponent } from './info-message/info-message.component';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';
import { BackButtonModule } from '@libs/shared-ui/components/back-button/back-button.module';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';

@NgModule({
    imports: [
        DirectivesModule,
        LittleComponentsModule,
        CommonModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        CalendarModule,
        SharedComponentsModule,
        PopupsModule,
        MobileHeaderModule,
        KebabMenuModule,
        StaticTagsModule,
        GroupListItemModule,
        GroupListItemMobileModule,
        OverlayModule,
        OnboardingModule,
        BackButtonModule,
        InstantAqiLegendModule,
        InstantAqiFaqModule,
        AqiIndicatorModule,
        ActiveInactiveTagModule,
        SubstanceTagModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        CrossButtonModule,
    ],
    exports: [
        AqiLegend,
        Logout,
        CsTopRightElements,
        TopRightElementsMobile,
        LayersButtonModelsComponent,
        LayersButtonPlumesComponent,
        ShowModelBtn,
        RunSummaryComponent,
        DownloadPopupsWrapperComponent,
        MobileHeaderComponent,
        OnboardingModule,
        InstantAqiLegendModule,
        InstantAqiFaqModule,
        ActiveInactiveTagModule,
        SubstanceTagModule,
        InfoMessageComponent,
    ],
    declarations: [
        AqiLegend,
        Logout,
        CsTopRightElements,
        TopRightElementsMobile,
        LayersButtonModelsComponent,
        LayersButtonPlumesComponent,
        ShowModelBtn,
        RunSummaryComponent,
        DownloadPopupsWrapperComponent,
        CapiAqiLegendComponent,
        InfoMessageComponent,
    ],
})
export class CityscreenComponentsModule {}
