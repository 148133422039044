export const SLIDES_DATA = [
    {
        subtitle: 'Что нового?',
        title: 'Добавили новые стили карты',
        description: [
            'Разные типы карт помогут лучше понимать зоны загрязнения в контексте реального ландшафта или городской инфраструктуры',
        ],
        imgUrl: 'onboarding/map-styles-07-2024/slide-1.png',
    },
];
