<div class="new-map-modal" *ngIf="slides?.[currentSlideIndex] as data">
    <div class="new-map-modal__close">
        <cross-button
            size="medium"
            data-cy="onboarding-popup-close"
            (click)="close()"
        ></cross-button>
    </div>
    <div class="new-map-modal__title">{{ data.title }}</div>
    <div class="new-map-modal__description">
        <p *ngFor="let description of data.description">{{ description }}</p>
    </div>
    <div class="new-map-modal__image">
        <cs-image-compare
            [legend]="{
                before: TEXTS.ONBOARDING.before,
                after: TEXTS.ONBOARDING.after
            }"
        >
            <img class="before" [attr.src]="data.imgUrlCompare" alt="CityAir Map (old)" />
            <img class="after" [attr.src]="data.imgUrl" alt="CityAir Map (new)" />
        </cs-image-compare>
    </div>
    <div class="new-map-modal__btn" (click)="close(true)">
        {{ TEXTS.ONBOARDING.doNotShowAgain }}
    </div>
</div>
