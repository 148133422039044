import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import type { SlideData } from '../../onboarding.type';

@Component({
    selector: 'cs-slides-onboarding-modal',
    templateUrl: 'slides-onboarding-modal.component.html',
    styleUrls: ['slides-onboarding-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlidesOnboardingModalComponent {
    @Input() slides: SlideData[];

    @Output() closePopup = new EventEmitter<boolean>();

    markedAsSeen = false;

    currentSlideIndex = 0;

    close() {
        this.closePopup.emit(this.markedAsSeen);
    }

    nextSlide() {
        if (this.currentSlideIndex < this.slides.length - 1) {
            this.currentSlideIndex++;
        }
    }

    selectSlide(item: SlideData) {
        this.currentSlideIndex = this.slides.indexOf(item);
    }

    isLastSlide() {
        return this.currentSlideIndex >= this.slides.length - 1;
    }
}
