import * as moment from 'moment-timezone';
import { MINUTE20_MS } from '@cityair/namespace';

export const PLUMES_FEATURE_KEY = 'plumes';

export const STATION_ENTITY_STORE_KEY = 'PlumesStation';

export const CONTROL_POINT_ENTITY_STORE_KEY = 'PlumesControlPoint';

export const RUN_CONFIG_ENTITY_STORE_KEY = 'PlumesRunConfig';

export const RUN_ENTITY_STORE_KEY = 'PlumesRun';

export const NEW_CONTROL_POINT_OBJ_TYPE = 'new_control_point';

export const CITYAIR_API_BASIC_URL = '/api/basic/v1';

export const AIRTRACER = 'AIRTRACER';

export const NUMBER_ROUND_COORDINATES = 6;
export const INTERVAL_UPDATE_RUNS = MINUTE20_MS;
