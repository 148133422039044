<div class="added-reports-popup" *ngIf="slides?.[currentSlideIndex] as data">
    <cross-button
        class="added-reports-popup__close-btn"
        data-cy="onboarding-popup-close"
        size="large"
        (click)="close()"
    ></cross-button>

    <div class="added-reports-popup__header">
        <div class="added-reports-popup__subtitle">{{ data.subtitle }}</div>
        <div class="added-reports-popup__title">{{ data.title }}</div>
        <div class="added-reports-popup__description">{{ data.description[0] }}</div>
    </div>
    
    <img class="added-reports-popup__preview" [attr.src]="data.imgUrl" alt="preview" />
    
    <div class="added-reports-popup__slide-controls">
        <div class="button-simple button-simple__primary" (click)="try()">
            {{ data.button }}
        </div>
    </div>
    <div class="added-reports-popup__mark-as-seen" (click)="markedAsSeen = !markedAsSeen;close()">
        <span class="added-reports-popup__checkbox-label">{{ data.hidePopup }}</span>
    </div>
</div>
