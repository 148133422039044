import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    ViewChild,
} from '@angular/core';

import { distinctUntilChanged, fromEvent, map, merge, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'cs-image-compare',
    templateUrl: './image-compare.component.html',
    styleUrls: ['./image-compare.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCompareComponent implements OnDestroy {
    @Input() legend: {
        before: string;
        after: string;
    };

    @ViewChild('container', { static: true }) container: ElementRef<HTMLDivElement>;

    drag = false;
    position = 50;

    onDestroy$ = new Subject<void>();

    constructor(private cd: ChangeDetectorRef) {}

    calcValue(x: number) {
        const containerRect = this.container.nativeElement.getBoundingClientRect();
        const containerWidth = containerRect.width;
        const offset = containerRect.x;
        const result = (100 * (x - offset)) / containerWidth;

        return Math.max(0, Math.min(result, 100));
    }

    updatePosition(x: number) {
        this.position = x;
        this.cd.markForCheck();
    }

    moveSlider(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.updatePosition(this.calcValue(event.clientX));
    }

    startHandle(event: MouseEvent) {
        event.preventDefault();

        const onRelease = fromEvent(window, 'mouseup');

        fromEvent(this.container.nativeElement, 'mousemove')
            .pipe(
                takeUntil(merge(onRelease, this.onDestroy$)),
                map((e: MouseEvent) => this.calcValue(e.clientX)),
                distinctUntilChanged()
            )
            .subscribe((x) => {
                this.updatePosition(x);
            });
    }

    startTouchHandle(event: TouchEvent) {
        event.preventDefault();

        const onRelease = fromEvent(window, 'touchend');

        fromEvent(this.container.nativeElement, 'touchmove')
            .pipe(
                takeUntil(merge(onRelease, this.onDestroy$)),
                map((e: TouchEvent) => this.calcValue(e.touches.item(0).clientX)),
                distinctUntilChanged()
            )
            .subscribe((x) => {
                this.updatePosition(x);
            });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
