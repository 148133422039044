import { TEXTS } from '@libs/common/texts/texts';

export const SLIDES_DATA = [
    {
        title: TEXTS.ONBOARDING.newMapStyle.title,
        description: TEXTS.ONBOARDING.newMapStyle.description,
        imgUrl: TEXTS.ONBOARDING.newMapStyle.imgUrl,
        imgUrlCompare: TEXTS.ONBOARDING.newMapStyle.imgUrlCompare,
    },
];
