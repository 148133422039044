<div class="onboarding-popup" *ngIf="slides?.[currentSlideIndex] as data">
    <cross-button
        class="onboarding-popup__close-btn"
        data-cy="onboarding-popup-close"
        size="large"
        (click)="close()"
    ></cross-button>
    <div class="onboarding-popup__title">{{ data.title }}</div>
    <img class="onboarding-popup__preview" [attr.src]="data.imgUrl" alt="preview" />
    <div class="onboarding-popup__description">
        <p *ngFor="let description of data.description">{{ description }}</p>
        <p class="onboarding-popup__more">
            <a class="onboarding-popup__more-link" href="#" (click)="openInstantAqiFaq($event)">
                Подробнее о преимуществах Instant AQI
            </a>
        </p>
    </div>
    <div class="onboarding-popup__slide-controls">
        <cs-button-simple type="primary" (click)="close()">Понятно</cs-button-simple>
    </div>
    <div class="onboarding-popup__mark-as-seen" (click)="markedAsSeen = !markedAsSeen">
        <cs-checkbox [checked]="markedAsSeen"></cs-checkbox>
        <span class="onboarding-popup__checkbox-label">Больше не показывать</span>
    </div>
</div>
