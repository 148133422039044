import { MeasureScheme } from '@libs/common/enums/measure-scheme';

export const DISPLAY_DEVICE_TYPE = 'CityairDevice';
export const FIELD_COMMON_FORM_ERROR = 'detail';
export enum BasicMetaTypeResponse {
    groupInfo = 'group_info',
    userInfo = 'user_info',
    groupTimeseries = 'group_timeseries',
}
export interface ValidationError {
    field: string;
    error: string;
}
export interface EventDateRange {
    start: string;
    end: string;
}
export interface ErrorDetails {
    internal_error_code: string;
    message: string;
    validation_errors: ValidationError[];
}

export interface ResponseMeta {
    extra: IExtraUserInfo | IExtraTimelineResponse | IExtraGroupInfoResponse;
    status: string;
    type: BasicMetaTypeResponse;
    error: ErrorDetails;
}
export interface IExtraGroupInfoResponse {
    device_sources: BasicDeviceSourceResponse[];
}
export interface IExtraUserInfo {
    group_roles: BasicRolesResponse[];
}
export interface IExtraTimelineResponse {
    dates: string[];
}
export interface IBasicResponseError {
    InternalCode: string;
}

export interface IBasicResponse<T = any | IBasicResponseError> {
    meta: ResponseMeta;
    data:
        | BasicDataResponse[]
        | BasicDataResponse
        | User
        | User[]
        | Group
        | NotificationSettings[]
        | CorrelationData
        | NetworkAnalysisData
        | WindData;
}

export enum DataObjType {
    group = 'group',
    outdoorPost = 'outdoor_post',
    post = 'post',
    city = 'locality',
    device = 'device',
    country = 'country',
    user = 'user',
    groupRole = 'group_role',
    notificationSettings = 'notification_settings',
    notification = 'notification',
}
export interface BasicDataResponse {
    id: string;
    name: string;
    obj?: string;
    name_ru?: string;
    ancestor?: BasicDataResponse;
    geometry?: Geometry;
    gmt_offset_seconds?: number;
}
export interface GroupRoleInfo {
    id: string;
    details?: { [key: string]: any[] };
}

export interface UserSettings {
    language: string;
    measure_scheme: MeasureScheme;
    use_pdk_shares?: boolean;
    timezone?: string;
}

export interface User extends BasicDataResponse {
    id: string;
    login: string;
    email: string;
    obj: DataObjType.user;
    group_roles?: BasicGroupItemResponse;
    settings?: UserSettings;
}
export interface GroupUser extends BasicDataResponse {
    id: string;
    login: string;
    email: string;
    is_admin?: boolean;
    last_active_at?: string;
    obj: DataObjType.user;
    group_role?: GroupRoleInfo;
}
export interface GroupModule {
    id: number;
    name: string;
}
export interface Group extends BasicDataResponse {
    id: string;
    group_icon_url: string;
    modules: GroupModule[];
    name: string;
    name_ru: string;
    posts_count: number;
    ext_config?: any;
    region_coefs: RegionCoefsDto;
    create_date: string;
}
export interface Post extends BasicDataResponse {
    obj: DataObjType.outdoorPost;
    available_indexes?: string[];
    available_measurements?: string[];
}
export interface PostShort extends BasicDataResponse {
    obj: DataObjType.outdoorPost;
}
export interface Locality extends BasicDataResponse {
    obj: DataObjType.city;
    available_indexes?: string[];
    available_measurements?: string[];
    is_small?: boolean;
    sort_rank?: number;
    geo?: Geometry;
}
export interface DeviceExtInfoDto {
    ps220: boolean;
    bat_ok: boolean;
    software_version: string;
    hardware_version: string;
    data_delivery_period_sec: number;
}
export interface Geometry {
    type: string;
    coordinates: [number, number];
}
export interface Device extends BasicDataResponse {
    obj: DataObjType.device;
    device_first_date: string;
    device_last_date: string;
    is_offline: boolean;
    is_on_service: boolean;
    serial_number: string;
    source_type: string;
    ext_info: DeviceExtInfoDto;
    ancestor?: PostShort;
    geometry: Geometry;
}
export interface DetailedTimeSeries {
    values: { [key: string]: number[] };
    details?: { [key: string]: number[] };
}
export interface TimeSeries {
    [key: string]: number[];
}
export interface TimeSeriesData {
    measurements: DetailedTimeSeries;
    indexes: DetailedTimeSeries;
}
export interface ITimeseriesDataItem extends BasicDataResponse {
    obj: DataObjType.city | DataObjType.outdoorPost;
    data?: TimeSeriesData;
}
export interface PostCreateRequest {
    group_id: string;
    name: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
    locality_id: string;
    device_id?: string;
}
export interface FeedItemsExportRequest {
    NotificationId: string;
    TimeBegin: string;
    TimeEnd: string;
    TimeZone: number;
    Lang: string;
}
export interface StationDataToExcelRequestProps {
    type: number;
    timeBegin: number;
    timeEnd: number;
    ids: number[];
}
export interface MoItemsDataToExcelRequestProps {
    timeBegin: number;
    timeEnd: number;
    moIds: string[];
    interval: number;
    type: number;
    unitsType: number;
    insertPdk: boolean;
}
export interface BasicGroupItemResponse {
    [key: number]: string;
}
export interface BasicDeviceSourceResponse {
    is_extension: boolean;
    name: string;
    name_ru: string;
    source_type: string;
}
export interface BasicRolesResponse {
    id: string;
    name: string;
    name_ru: string;
    edit_group: boolean;
    edit_station: boolean;
    edit_user: boolean;
    view_device_data: boolean;
    view_station_data: boolean;
    edit_device_properties: boolean;
}
export enum DistributionSummaryEnum {
    year = 'year',
    winter = 'winter',
    spring = 'spring',
    summer = 'summer',
    autumn = 'autumn',
}
export interface AqiDistributionItem {
    aqi: number;
    percent: number;
}

export interface HourDistributionItem {
    hour_of_day: number;
    aqi: number;
}

export interface WeekDistributionItem {
    day_of_week: number;
    aqi: number;
}

export interface DistributionSummaryItem {
    first_packet_date: string;
    distribution_aqi: AqiDistributionItem[];
    distribution_day_hour: HourDistributionItem[];
    distribution_week_day: WeekDistributionItem[];
}
export interface LocalitySummary {
    [DistributionSummaryEnum.year]: DistributionSummaryItem;
    [DistributionSummaryEnum.winter]: DistributionSummaryItem;
    [DistributionSummaryEnum.spring]: DistributionSummaryItem;
    [DistributionSummaryEnum.summer]: DistributionSummaryItem;
    [DistributionSummaryEnum.autumn]: DistributionSummaryItem;
}
export interface BasicSummaryResponse {
    locality_id: string;
    group_id: string;
    update_time: string;
    distribution_summary_items: LocalitySummary;
}
export enum NotificationType {
    pdkExcess = 'PdkExcess',
    deviceIncident = 'DeviceIncident',
}
export interface NotifySettings {
    daily_report_emails: string[];
    notification_emails: string[];
}
export enum MoIntervalTypeEnum {
    interval5M = 'Interval5M',
    interval20M = 'Interval20M',
    interval1H = 'Interval1H',
    interval1D = 'Interval1D',
    source = 'Source',
}
export interface PdkCheckSettings {
    interval_type: MoIntervalTypeEnum;
    is_for_all_posts: boolean;
    is_for_any_value_type: boolean;
    pdk_mr_multiplier: number;
    selected_posts: string[];
    selected_value_types: string[];
    should_notify_every_interval: boolean;
}
export interface DeviceCheckSettings {
    is_for_all_posts: boolean;
    selected_posts: string[];
    selected_value_types: string[];
    no_data_duration_threshold: number;
    no_ps220_duration_threshold: number;
    verification_threshold: number;
}
export interface NotificationSettings {
    id?: string;
    name?: string;
    type: NotificationType;
    group_id?: string;
    group: string;
    is_active: boolean;
    obj: DataObjType.notification;
    notify_settings: NotifySettings;
    pdk_check_settings?: PdkCheckSettings;
    device_check_settings?: DeviceCheckSettings;
}
export interface NotificationExcessInfo {
    max_value: number;
    pdk_value: number;
    pdk_multiplier: number;
}
export interface NotificationServiceExcessInfo {
    post_id: string;
    device_id: string;
    device_serial: string;
    device_source: string;
}
export enum EventStatus {
    None = 'None',
    Begin = 'Begin',
    Continue = 'Continue',
    End = 'End',
}
export enum ServiceExcessType {
    Unknown = 'unknown',
    DeviceNoPs220 = 'DeviceNoPs220',
    DeviceNoData = 'DeviceNoData',
}
export interface BaseNotificationEvent {
    id: string;
    settings_id: string;
    type: NotificationType;
    group_id: string;
    is_viewed: boolean;
    obj: DataObjType.notification;
    pdk_value_type: string;
    status: EventStatus;
    begin_time: string;
    end_time: string;
    update_time: string;
    device_id?: string;
    service_excess_type?: ServiceExcessType;
    service_excess_info?: NotificationServiceExcessInfo;
    excess_info?: NotificationExcessInfo;
    post_id?: string;
}
export interface NotificationQueryParams {
    group: string;
    take: number;
    skip: number;
    type: NotificationType;
}
export interface GroupUserParams {
    group?: string;
    email?: string;
    login?: string;
    user?: string;
    role: string;
    posts: string[];
}
export interface BasicUserTokens {
    TokenId: number;
    UserId: number;
    Title: string;
    IsApiKey: boolean;
    CreateDate?: string;
    LastDate?: string;
    ExecCount?: number;
    RequestIp?: string;
    UserAgent?: string;
}
export interface RegionCoefsDto {
    id: string;
    name: string;
    fix_pcf?: number;
    fix_scf?: number;
    description: string;
}
export interface RegionCoefsResponse {
    Result: {
        RegionCoefsId: number;
        Name: string;
        Description: string;
        Pcf: number;
        Scf: number;
    };
}
export interface DataCorrelation {
    [key: string]: { [key: string]: number };
}
export enum AnalysisEventStatus {
    new = 'NEW',
    wait = 'WAIT',
    cancel = 'CANCEL',
    accept = 'ACCEPT',
}
export enum AnalysisEventStatusByEnd {
    new = 'NEW',
    end = 'END',
}
export enum AnalysisEventType {
    global = 'global',
    local = 'local',
}
export interface GroupEvent {
    post_id: string;
    start: string;
    end: string;
    max_value: number;
}
export interface AnalysisEvent {
    events: GroupEvent[];
    id: string;
    start: string;
    forecast_end?: string;
    end: string;
    param: string;
    pdk_value: number;
    status?: AnalysisEventStatus;
    type?: AnalysisEventType;
    verify?: number;
}

export interface IAnalysisEvent extends AnalysisEvent {
    statusDuration: EventStatus;
    start_ms: number;
    end_ms: number;
    duration: string;
    forecast_end: string;
    forecast_end_time: string;
    status_rank: number;
    duration_ms: number;
    status?: AnalysisEventStatus;
    verify?: number;
    type?: AnalysisEventType;
    uniqueEvents?: GroupEvent[];
    eventTypeByEnd?: AnalysisEventStatusByEnd;
}
export interface AverageDurationsStats {
    min: number;
    max: number;
    percentage: number;
}
export interface EventData {
    by_hours_of_day?: { [key: string]: number[] };
    general_stats?: { [key: number]: number };
    average_durations_stats: AverageDurationsStats[];
}
export interface CorrelationAnalysisData {
    name: string;
    first_post: string;
    first_name: string;
    second_post: string;
    second_name: string;
    dist?: number;
    value?: number;
}
export enum CorrelationPeriod {
    day = '1d',
    month = '1m',
    quarter = '3m',
    halfYear = '6m',
    year = '1y',
}
export interface CorrelationDataDetails {
    start_date: string;
    end_date: string;
    coefficients: DataCorrelation;
}
export interface CorrelationData {
    [key: string]: {
        [key: string]: CorrelationDataDetails;
    };
}
export interface CorrelationPostData {
    '24h': { [key: string]: number };
    '1m': { [key: string]: number };
}
export interface SortingData {
    name: string;
    dir: number;
}
export interface NetworkAnalysisData {
    network_area_living: number;
    network_area_total: number;
    stations_avg_min_distance?: number;
    stations_count: number;
    stations_coverage?: number;
    recommendations?: string[];
}
export interface WindDataPost {
    velocity: number;
    direction: string;
    daily_stats: {
        [key: string]: number;
    };
}
export interface WindData {
    [key: string]: WindDataPost;
}
