export const SLIDES_DATA = [
    {
        title: 'Новый, более актуальный индекс',
        description: [
            'Мы изменили время усреднения при расчете AQI с 24 часов на 1 час, и наш индекс качества воздуха стал «мгновенным». Теперь он называется Instant AQI и отображает более актуальное состояние окружающей атмосферы.',
            'При этом мы сохранили возможность пользоваться прежним EPA AQI — он останется в отчётах.',
        ],
        imgUrl: 'onboarding/instant-aqi-03-2022/instant-aqi-slide-1.png',
    },
];
