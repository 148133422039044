export const SLIDES_DATA = [
    {
        subtitle: 'Новый экспериментальный модуль Анализ сети',
        title: 'От постов к сетям',
        description: ['Оцените параметры своей сети и примите решение о ее развитии'],
        imgUrl: 'onboarding/analysis-11-2023/slide-1.png',
    },
    {
        title: 'Вкладка Сводка',
        description: [
            'Вы можете посмотреть сводную информацию о своей сети мониторинга, включая плотность сети, зоны рекомендуемого расширения, покрытие территории текущими постами, а также список постов, на которые стоит обратить внимание из-за низкого коэффициента корреляции данных.',
        ],
        imgUrl: 'onboarding/analysis-11-2023/slide-2.png',
    },
    {
        title: 'Вкладка Посты',
        description: [
            'Переключайтесь между постами мониторинга для визуального отображения коэффициента корреляции с другими постами сети',
        ],
        imgUrl: 'onboarding/analysis-11-2023/slide-3.png',
    },
    {
        title: 'Вкладка Связи',
        description: [
            'Используйте список связей, чтобы оценить корреляцию измерений между всеми парами постов. При клике на выбранную пару откроется график измерений и корреляций за выбранный период.',
        ],
        imgUrl: 'onboarding/analysis-11-2023/slide-4.png',
        button: 'Попробовать',
    },
];
