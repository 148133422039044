<div
    #container
    class="image-compare"
    (click)="moveSlider($event)"
    (mousedown)="startHandle($event)"
    (touchstart)="startTouchHandle($event)"
>
    <div class="image-compare__legend" *ngIf="legend">
        <div class="before">{{ legend.before }}</div>
        <div class="after">{{ legend.after }}</div>
    </div>
    <div class="image-compare-wrapper">
        <div class="image-compare__overlay" [style.left.%]="position">
            <div class="image-compare__handle"></div>
        </div>
        <div class="image-compare__before">
            <ng-content select="img.before"></ng-content>
        </div>
        <div
            class="image-compare__after"
            [style.clip-path]="
                'polygon(' + position + '% 0, 100% 0, 100% 100%, ' + position + '% 100%)'
            "
        >
            <ng-content select="img.after"></ng-content>
        </div>
    </div>
</div>
