import { Inject, Injectable, InjectionToken, NgZone, Optional, SimpleChange } from '@angular/core';
import { AsyncSubject, first, Observable, Subscription } from 'rxjs';
import { LayerEvents, OlMapEvent, OverlayEvents } from './map.types';
import Map from 'ol/Map';
import View, { ViewOptions } from 'ol/View';
import { applyTransform, Extent } from 'ol/extent';
import { MapboxVectorLayer } from 'ol-mapbox-style';
import { getTransform, useGeographic } from 'ol/proj';
import Overlay, { Options } from 'ol/Overlay';
import { MAP_ACTION_DURATION } from '@libs/common/consts/map.const';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { LoadFunction } from 'ol/Image';
import { Layer } from 'ol/layer';
import { DblClickDragZoom, defaults as defaultInteractions } from 'ol/interaction.js';
import TileLayer from 'ol/layer/Tile';
import { OSM, XYZ } from 'ol/source';
import { Attribution, defaults as defaultControls } from 'ol/control.js';
import { MapStyleType } from '@libs/common/enums/map-style-type.type';
import { isRU } from '@libs/common/texts/texts';
import { OUTDOOR_STYLE } from '@libs/common/consts/map.const';

export const MAP_API_KEY = new InjectionToken('MapApiKey');
export const YANDEX_API_KEY = new InjectionToken('YandexMapApiKey');
export interface mapOptionsSetup {
    key?: string;
    style?: string;
    type: MapStyleType;
    attribution?: HTMLElement;
}
export interface SetupMap {
    mapOptions: mapOptionsSetup;
    viewOptions: ViewOptions;
    mapEvents: OlMapEvent;
}
export interface SetupLayer {
    layerOptions: Layer;
    layerEvents: LayerEvents;
}
export interface SetupOverlay {
    overlayOptions: Options;
    overlayEvents: OverlayEvents;
}
export interface SetupImage {
    imageOptions: {
        opacity: number;
    };
    sourceOptions: {
        url: string;
        projection: string;
        imageExtent: Extent;
        imageLoadFunction: LoadFunction;
    };
}
@Injectable()
export class MapService {
    mapInstance: Map;
    mapCreated$: Observable<void>;
    mapLoaded$: Observable<void>;
    mapEvents: OlMapEvent;

    private mapCreated = new AsyncSubject<void>();
    private mapLoaded = new AsyncSubject<void>();
    private subscription = new Subscription();
    private markersToRemove: any[] = [];
    private mainLayer: Layer;
    private mapboxLayer: MapboxVectorLayer;
    private mapboxOutdoorLayer: MapboxVectorLayer;
    constructor(
        private zone: NgZone,
        @Optional()
        @Inject(MAP_API_KEY)
        private readonly MAP_API_KEY: string | null,
        @Optional()
        @Inject(YANDEX_API_KEY)
        private readonly YANDEX_API_KEY: string | null
    ) {
        this.mapCreated$ = this.mapCreated.asObservable();
        this.mapLoaded$ = this.mapLoaded.asObservable();
    }
    setup(container: HTMLElement, options: SetupMap) {
        // Need onStable to wait for a potential @angular/route transition to end
        this.zone.onStable.pipe(first()).subscribe(() => {
            const mapOptions = {
                ...options.mapOptions,
                key: options.mapOptions.key || this.MAP_API_KEY || '',
            };
            this.createMap(container, mapOptions, options.viewOptions, options.mapEvents);
            this.hookEvents(options.mapEvents);
            this.mapEvents = options.mapEvents;
            this.mapCreated.next(undefined);
            this.mapCreated.complete();
            if (options.mapEvents.mapCreate.observed) {
                this.zone.run(() => {
                    options.mapEvents.mapCreate.emit(this.mapInstance);
                });
            }
        });
    }

    getMap() {
        return this.mapInstance;
    }

    destroyMap() {
        if (this.mapInstance) {
            this.subscription.unsubscribe();
            this.mapInstance.setTarget(null);
        }
    }

    updateMinZoom(minZoom: number) {
        return this.zone.runOutsideAngular(() => {
            const view = this.mapInstance.getView();
            const options = view.getUpdatedOptions_({ minZoom });
        });
    }

    updateMapType(type: SimpleChange) {
        this.zone.runOutsideAngular(() => {
            if (this.isTileLayer(type.previousValue) && this.isTileLayer(type.currentValue)) {
                const source = this.getSourceByType(type.currentValue);
                if (source) {
                    this.mainLayer.setSource(source);
                }
            } else if (
                this.isTileLayer(type.previousValue) &&
                this.isMapboxLayer(type.currentValue)
            ) {
                if (type.currentValue === MapStyleType.outdoor) {
                    this.mapboxOutdoorLayer.setVisible(true);
                } else {
                    this.mapboxLayer.setVisible(true);
                }
                this.mainLayer.setVisible(false);
            } else if (
                this.isMapboxLayer(type.previousValue) &&
                this.isTileLayer(type.currentValue)
            ) {
                if (type.previousValue === MapStyleType.outdoor) {
                    this.mapboxOutdoorLayer.setVisible(false);
                } else if (type.previousValue === MapStyleType.cityair) {
                    this.mapboxLayer.setVisible(false);
                }
                const source = this.getSourceByType(type.currentValue);
                this.mainLayer.setSource(source);
                this.mainLayer.setVisible(true);
            } else if (
                this.isMapboxLayer(type.previousValue) &&
                this.isMapboxLayer(type.currentValue)
            ) {
                if (type.previousValue === MapStyleType.outdoor) {
                    this.mapboxOutdoorLayer.setVisible(false);
                } else if (type.previousValue === MapStyleType.cityair) {
                    this.mapboxLayer.setVisible(false);
                }
                if (type.currentValue === MapStyleType.outdoor) {
                    this.mapboxOutdoorLayer.setVisible(true);
                } else if (type.currentValue === MapStyleType.cityair) {
                    this.mapboxLayer.setVisible(true);
                }
            }
        });
    }

    updateMaxZoom(maxZoom: number) {
        return this.zone.runOutsideAngular(() => {
            const view = this.mapInstance.getView();
            const options = view.getUpdatedOptions_({ maxZoom });
        });
    }

    updateMaxBounds(maxBounds: Extent) {
        return this.zone.runOutsideAngular(() => {
            const view = this.mapInstance.getView();
            const center = view.getCenter();
            const zoom = view.getZoom();
            view.fit(maxBounds, { size: this.mapInstance.getSize() });
            this.mapInstance.setView(
                new View({
                    center,
                    extent: view.calculateExtent(this.mapInstance.getSize()),
                    zoom,
                })
            );
        });
    }

    move(center: number[], zoom: number) {
        const duration = MAP_ACTION_DURATION;
        this.zone.runOutsideAngular(() => {
            const view = this.mapInstance.getView();
            const currentCenter = center ? center : view.getCenter();
            if (currentCenter && zoom) {
                view.animate({ zoom, center: currentCenter, duration });
            } else if (currentCenter) {
                view.animate({ center: currentCenter, duration });
            } else if (zoom) {
                view.animate({ zoom, duration });
            }
        });
    }

    addLayer(layer: Layer) {
        this.zone.runOutsideAngular(() => {
            this.mapInstance.addLayer(layer);
        });
    }

    removeLayer(layer: Layer) {
        this.zone.runOutsideAngular(() => {
            this.mapInstance.removeLayer(layer);
        });
    }

    addOverlay(overlay: SetupOverlay) {
        const options: Options = {
            id: overlay.overlayOptions.id,
            element: overlay.overlayOptions.element,
            offset: overlay.overlayOptions.offset,
            position: overlay.overlayOptions.position,
            positioning: overlay.overlayOptions.positioning,
            stopEvent: overlay.overlayOptions.stopEvent,
            insertFirst: overlay.overlayOptions.insertFirst ?? undefined,
            autoPan: overlay.overlayOptions.autoPan,
            className: overlay.overlayOptions.className,
        };
        Object.keys(options).forEach((key: string) => {
            const tkey = key as keyof Options;
            if (options[tkey] === undefined) {
                delete options[tkey];
            }
        });
        const overlayInstance = new Overlay(options);
        const self = this;
        if (overlay.overlayEvents.overlayDragEnd.observed) {
            this.mapInstance.on('pointermove', function (evt) {
                if (overlayInstance.get('dragging') === true) {
                    overlayInstance.setPosition(evt.coordinate);
                }
            });
        }

        return this.zone.runOutsideAngular(() => {
            this.mapInstance.addOverlay(overlayInstance);
            return overlayInstance;
        });
    }

    addImage(image: SetupImage) {
        return this.zone.runOutsideAngular(() => {
            const imageExtent = applyTransform(
                image.sourceOptions.imageExtent,
                getTransform('EPSG:4326', 'EPSG:3857')
            );
            const imageLayer = new ImageLayer({
                source: new Static({
                    url: image.sourceOptions.url,
                    projection: image.sourceOptions.projection,
                    imageExtent,
                    imageLoadFunction: image.sourceOptions.imageLoadFunction,
                }),
                opacity: image.imageOptions.opacity,
            });

            this.mapInstance.addLayer(imageLayer);
            return imageLayer;
        });
    }

    updateImage(layer: Layer, image: SetupImage) {
        return this.zone.runOutsideAngular(() => {
            const imageExtent = applyTransform(
                image.sourceOptions.imageExtent,
                getTransform('EPSG:4326', 'EPSG:3857')
            );
            layer.setSource(
                new Static({
                    url: image.sourceOptions.url,
                    projection: image.sourceOptions.projection,
                    imageExtent,
                    imageLoadFunction: image.sourceOptions.imageLoadFunction,
                })
            );

            return layer;
        });
    }

    removeMarker(marker) {
        this.markersToRemove.push(marker);
    }

    applyChanges() {
        this.zone.runOutsideAngular(() => {
            this.removeMarkers();
        });
    }

    private createMap(
        container: HTMLElement,
        mapOptions: mapOptionsSetup,
        options: ViewOptions,
        events: OlMapEvent
    ) {
        NgZone.assertNotInAngularZone();
        Object.keys(options).forEach((key: string) => {
            const tkey = key as keyof ViewOptions;
            if (options[tkey] === undefined) {
                delete options[tkey];
            }
        });
        useGeographic();
        const view = new View(options);
        const layers = [];
        this.mainLayer = new TileLayer({
            source: this.getSourceByType(mapOptions.type),
            visible: this.isTileLayer(mapOptions.type),
        });
        layers.push(this.mainLayer);
        this.mapboxLayer = new MapboxVectorLayer({
            styleUrl: mapOptions.style,
            accessToken: mapOptions.key,
            updateWhileAnimating: true,
            visible: mapOptions.type === MapStyleType.cityair,
        });
        layers.push(this.mapboxLayer);
        this.mapboxOutdoorLayer = new MapboxVectorLayer({
            styleUrl: OUTDOOR_STYLE,
            accessToken: mapOptions.key,
            updateWhileAnimating: true,
            visible: mapOptions.type === MapStyleType.outdoor,
        });
        layers.push(this.mapboxOutdoorLayer);
        const attribution = new Attribution({
            target: mapOptions.attribution,
        });

        this.mapInstance = new Map({
            interactions: defaultInteractions({
                altShiftDragRotate: false,
                pinchRotate: false,
            }).extend([new DblClickDragZoom()]),
            layers,
            target: container,
            view: view,
            controls: defaultControls({ attribution: false, zoom: false }).extend([attribution]),
        });

        this.subscription.add(this.zone.onMicrotaskEmpty.subscribe(() => this.applyChanges()));
    }

    private hookEvents(events: OlMapEvent) {
        this.mapCreated$.subscribe(() => {
            this.mapLoaded.next(undefined);
            this.mapLoaded.complete();
            this.zone.run(() => {
                events.mapLoad.emit(this.mapInstance);
            });
        });

        if (events.zoomEvt.observed) {
            const view = this.mapInstance.getView();
            view.on('change:resolution', (evt) => {
                const currentZoom = view.getZoom();
                const center = view.getCenter();
                this.zone.run(() => {
                    events.zoomEvt.emit({ zoom: currentZoom, center });
                });
            });
        }

        if (events.mapClick.observed) {
            this.mapInstance.on('click', (evt) =>
                this.zone.run(() => {
                    const point = this.mapInstance.getCoordinateFromPixel(evt.pixel);
                    events.mapClick.emit(point);
                })
            );
        }

        if (events.mapMoveEnd.observed) {
            this.mapInstance.on('moveend', (evt) => {
                const view = this.mapInstance.getView();
                const center = view.getCenter();
                const zoom = view.getZoom();
                if (isNaN(center[0]) || isNaN(center[1])) {
                    return;
                }
                this.zone.run(() => {
                    events.mapMoveEnd.emit({ center, zoom });
                });
            });
        }

        if (events.mapMoveStart.observed) {
            this.mapInstance.on('movestart', (evt) => {
                this.zone.run(() => {
                    events.mapMoveStart.emit();
                });
            });
        }
    }

    private getSourceByType(type) {
        if (type === MapStyleType.yandex) {
            const lang = isRU ? 'ru_RU' : 'en_US';
            const scale = window.devicePixelRatio > 1 ? 2 : 1;
            return new XYZ({
                url: `https://tiles.api-maps.yandex.ru/v1/tiles/?apikey=${this.YANDEX_API_KEY}&lang=${lang}&x={x}&y={y}&z={z}&l=map&scale=${scale}&projection=web_mercator`,
                tilePixelRatio: scale,
            });
        } else if (type === MapStyleType.satellite) {
            return new XYZ({
                //url: `https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
                url: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${this.MAP_API_KEY}`,
            });
        } else if (type === MapStyleType.osm) {
            return new OSM();
        }
        return null;
    }

    private removeMarkers() {
        for (const marker of this.markersToRemove) {
            marker.setMap(null);
        }
        this.markersToRemove = [];
    }

    private assign(obj: any, prop: any, value: any) {
        if (typeof prop === 'string') {
            // eslint-disable-next-line no-param-reassign
            prop = prop.split('.');
        }
        if (prop.length > 1) {
            const e = prop.shift();
            this.assign(
                (obj[e] =
                    Object.prototype.toString.call(obj[e]) === '[object Object]' ? obj[e] : {}),
                prop,
                value
            );
        } else {
            obj[prop[0]] = value;
        }
    }

    private isTileLayer(type) {
        return (
            type === MapStyleType.yandex ||
            type === MapStyleType.osm ||
            type === MapStyleType.satellite
        );
    }

    private isMapboxLayer(type) {
        return type === MapStyleType.cityair || type === MapStyleType.outdoor;
    }
}
