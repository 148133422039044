import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isFalseNumber } from '@libs/common/utils/utils';
import { LANGUAGE } from '@libs/common/texts/texts';
import { formatNumber } from '@angular/common';
import { SOURCES_TYPE_BY_ID } from '@libs/common/consts/demo-impact-groups';

@Component({
    standalone: true,
    selector: 'cityair-impact-source-pin',
    templateUrl: './impact-source-pin.component.html',
    styleUrls: ['./impact-source-pin.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactSourcePinComponent implements OnChanges {
    @Input() name: string;
    @Input() id: number;
    type = 'factory'; //factory, farma,tube, trash
    SOURCES_TYPE_BY_ID = SOURCES_TYPE_BY_ID;
    ngOnChanges(changes: SimpleChanges): void {
        this.type = SOURCES_TYPE_BY_ID[this.id] ?? 'factory';
    }
}
