import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment-timezone';
import { RunPlume } from '@cityair/modules/plumes/services/run/models';
import { TIME_FORMAT } from '@libs/common/consts/date-format.const';
import { formatDayMonth } from '@cityair/config';

function formatTime(m: moment.Moment) {
    return m.format(TIME_FORMAT);
}
function formatDate(m: moment.Moment) {
    return `${formatTime(m)} ${formatDayMonth(m)}`;
}
@Pipe({
    name: 'plumesRunTime',
})
export class PlumesRunTimePipe implements PipeTransform {
    transform(run: RunPlume): string {
        const dateStart = moment(run.evaluation_time || 0);
        const dateEnd = moment(dateStart).add(run.duration_minutes, 'minutes');
        return dateStart.isSame(dateEnd, 'day')
            ? `${formatTime(dateStart)} – ${formatDate(dateEnd)}`
            : `${formatDate(dateStart)} –
           ${formatDate(dateEnd)}`;
    }
}
