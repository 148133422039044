import * as moment from 'moment-timezone';

import {
    AqiHistory_model,
    City_model,
    DAY_MS,
    IntervalEnum,
    IntervalType,
    StartOutsideParams,
} from '@cityair/namespace';

import { AVAILABLE_INTERVALS, getColorIndex } from '@cityair/config';
import { NO_DATA_COLOR } from '@libs/common/consts/no-data-color.const';
import { TEXTS } from '@libs/common/texts/texts';
import { ColorZone } from '@libs/common/types/color-zone';
import { isFalseNumber } from '@libs/common/utils/utils';

declare const window: any;

export function isOldBrowser() {
    if (!window.navigator) return true;

    const ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0) return true;

    return false;
}

export function detectTouchDevice() {
    const prefixes = ['-webkit-', '-moz-', '-o-', '-ms-'];

    if (
        'ontouchstart' in window ||
        (window.DocumentTouch && document instanceof window.DocumentTouch)
    ) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return window.matchMedia(query).matches;
}

function _findPoint(data, currentX) {
    let delta = 24 * 60 * 60 * 1000; // 1 day
    let originalIndex;

    for (let i = 0; i < data.length; i++) {
        let val = data[i];
        val = val ? (val.x ? val.x : val[0]) : 0;
        const abs = Math.abs(currentX - val);
        if (abs < delta) {
            delta = abs;
            originalIndex = i;
        }
    }

    return originalIndex;
}

function _findPointForCoordinates(data, coordinateX) {
    let delta = 5; // 1 dey
    let originalIndex;

    for (let i = 0; i < data.length; i++) {
        const abs = Math.abs(coordinateX - data[i].clientX);
        if (abs < delta) {
            delta = abs;
            originalIndex = i;
        }
    }

    return originalIndex;
}

export function findPointFor(
    data: AqiHistory_model[],
    currentX: number,
    name: string,
    delta: number = DAY_MS
) {
    let originalIndex;

    for (let i = 0; i < data.length; i++) {
        const val = data[i][name] || 0;
        const abs = Math.abs(currentX - val);
        if (abs < delta) {
            delta = abs;
            originalIndex = i;
        }
    }

    return originalIndex;
}

export function findMuchPointInSeries(data, currentX) {
    return _findPoint(data, currentX);
}

export function findMuchPointInAllSeries(series = [], currentX) {
    let arrPoints = series.map((serie) => {
        const data = serie.data.length ? serie.data : serie.groupedData;

        if (!data || !data.length) return null;

        const index = _findPoint(data, currentX);
        return data[index];
    });

    arrPoints = arrPoints.filter((a) => !!a);

    if (!arrPoints.length) return null;

    const index = _findPoint(arrPoints, currentX);
    return arrPoints[index];
}

export function findPointInAllSeriesForCoordinates(series, coordinateX) {
    let arrPoints = series.map((serie) => {
        const data = serie.points;

        if (!data.length) return null;

        const pointIndex = _findPointForCoordinates(data, coordinateX);

        if (isFalseNumber(pointIndex)) return null;

        return data[pointIndex];
    });

    arrPoints = arrPoints.filter((a) => a && a.y !== null);

    if (!arrPoints.length) return null;

    const index = _findPointForCoordinates(arrPoints, coordinateX);
    return arrPoints[index];
}

export function findExactlyPoint(serie, point) {
    return serie.data.find((p) => {
        if (!p) return false;

        const val = p.x ? p.x : p[0];
        return val === point.x || val === point[0];
    });
}

export function excludeNavigatorSerie(series: any[] = []): any[] {
    // TODO: test
    return series.filter((s) => !(s.sharedClipKey && s.sharedClipKey.indexOf('navigator') > -1));
}

// ----------------------------------------------------------------------------------------------------------------------

export const isNumber = (n) => !isNaN(parseFloat(n)) && isFinite(n);

export function findMaxMinInForChart(data: [number, number][]): { min: number; max: number } {
    let min;
    let max;

    for (let i = 0; i < data.length; i++) {
        const val = data[i][1];

        if (min === undefined && !isFalseNumber(val)) min = val;
        if (max === undefined && !isFalseNumber(val)) max = val;

        if (val !== null) {
            max = Math.max(val, max);
            min = Math.min(val, min);
        }
    }

    return { min, max };
}

export const applyCoef = (
    val,
    coef: { a: number; b: number } = {
        a: 1,
        b: 0,
    }
): number => (isFalseNumber(val) ? null : val * coef.a + coef.b); // проблема когда val = 0 || null

export const calcDays = (timeBegin: number, timeEnd: number) =>
    moment(timeEnd).diff(moment(timeBegin), 'days', true);

export function differentObject(a: any, b: any, revert: boolean) {
    let ret = false;
    if ((!b && a) || (!a && b)) return true;

    for (const key in a) {
        if (a.hasOwnProperty(key)) {
            if (typeof a[key] !== typeof b[key]) return true;

            if (typeof a[key] === 'object') ret = differentObject(a[key], b[key], false);
            else if (a[key] !== b[key]) return true;

            if (ret) return ret;
        }
    }

    if (revert) {
        return differentObject(b, a, false);
    }
}

/// -----------------------------------------------------TEXTS----------------------------------------------------

export function getRandomText(arr: string[]) {
    return arr[Math.round(Math.random() * (arr.length - 1))];
}

function transformAqiGradation(aqi: number): 0 | 1 | 2 {
    if (aqi <= 3) return 0;
    if (aqi <= 6) return 1;
    if (aqi <= 10) return 2;
}

export function getQualityText(nowAqi: number): string {
    const i = transformAqiGradation(nowAqi);
    return getRandomText(TEXTS.QUALITY_CURRENT[i]);
}

export function getQualityForecastText(nowAqi: number, lastAqi: number): string {
    if (!nowAqi || !lastAqi) {
        return '';
    }

    const nowIndex = transformAqiGradation(nowAqi);

    let text: string[][];
    if (nowAqi < lastAqi) text = TEXTS.QUALITY_FORECAST['now<last'];
    if (nowAqi === lastAqi) text = TEXTS.QUALITY_FORECAST['now=last'];
    if (nowAqi > lastAqi) text = TEXTS.QUALITY_FORECAST['now>last'];

    return getRandomText(text[nowIndex]);
}

export function parseUrl(): StartOutsideParams {
    const retObj: StartOutsideParams = {};
    const url = (window as Window).location.search;

    if (url) {
        const urlParam = decodeURIComponent(url.substring(1))
            .split('&')
            .map((str) => str.split('='));

        urlParam.forEach((arr) => {
            try {
                if (arr[0] && arr[1])
                    retObj[arr[0]] = ~arr[1].indexOf('[') ? JSON.parse(arr[1]) : arr[1];
            } catch (e) {
                console.error(e);
            }
        });
    }

    if (window.location.hash) {
        retObj.hash = window.location.hash.substring(1);
    }

    return retObj;
}

export function getCityIdForCoords(cities: City_model[] = [], lat: number, lng: number): string {
    const city = cities.find(
        (c) =>
            c.bounds.west <= lng &&
            lng <= c.bounds.east &&
            c.bounds.south <= lat &&
            lat <= c.bounds.north
    );

    return city?.id || null;
}

export function createBoundaries(bbox: number[]): [number, number][] {
    const [west, south, east, north] = bbox;

    return [
        [west, north],
        [east, north],
        [east, south],
        [west, south],
    ];
}

export function createBBoxFromRectangle(coordinates: number[][]): number[] {
    let bbox = [];

    if (coordinates.length === 4) {
        const [west, north] = coordinates[0];
        const [east, south] = coordinates[2];

        bbox = [west, south, east, north];
    }

    return bbox;
}

export function createTimeSequence(timeBegin: number, timeEnd: number): Set<number> {
    const timeSequence: Set<number> = new Set();
    const time = moment.utc(timeBegin).startOf('hour');
    while (time < moment.utc(timeEnd)) {
        timeSequence.add(time.valueOf());
        time.add(1, 'hour');
    }

    return timeSequence;
}

// TODO: unify with createTimeSequence
export function createTimeSequencePlumes(timeBegin: number, timeEnd: number): Set<number> {
    const timeSequence: Set<number> = new Set();

    const time = moment.utc(timeBegin);
    while (time < moment.utc(timeEnd)) {
        timeSequence.add(time.valueOf());
        time.add(20, 'minutes');
    }

    return timeSequence;
}

function base64toBlob(b64Data, contentType = 'application/octet-binary', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export const saveDataToDisk = (data, filename) => {
    const blob = base64toBlob(data);
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
    downloadLink.setAttribute('download', filename);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
};
export const savePostDataToDisk = (data, filename) => {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', window.URL.createObjectURL(data));
    downloadLink.setAttribute('download', filename);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
};
export const hideHtmlLoader = () => {
    const elemStyle = window.commonLoader?.style;

    if (!elemStyle) {
        return;
    }

    elemStyle.opacity = '0';
    setTimeout(() => {
        elemStyle.display = 'none';
    }, 200);
};

export function celsiusToFahrenheit(value: number) {
    return value * 1.8 + 32;
}
export function getDaysByInterval(start: string, end: string): number {
    const startTime = moment(start);
    const endTime = moment(end);
    return moment.duration(endTime.diff(startTime)).asDays();
}

export function isDisabledTabByIntervalSwitcher(type: IntervalType, days: number) {
    return days > AVAILABLE_INTERVALS.find((i) => type === i.interval)?.days;
}

export function findMinInterval(days: number) {
    return AVAILABLE_INTERVALS.find((i) => days < i.days);
}

export function getColorFromZone(colorZone: ColorZone, value: number) {
    if (isFalseNumber(value) || !colorZone) return NO_DATA_COLOR;

    const index = getColorIndex(colorZone.zone, value);

    return colorZone.color?.[index] ?? NO_DATA_COLOR;
}
