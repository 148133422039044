import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment-timezone';
import {
    dateRangeText,
    formatDuration,
    formatMonthName,
    shortDateFormatWithToday,
} from '@libs/common/utils/date-formats';
import { LANGUAGE } from '@libs/common/texts/texts';
import { MONTH_FORMAT, TIME_FORMAT } from '@libs/common/consts/date-format.const';
import { customNumberFormat, isFalseNumber } from '@libs/common/utils/utils';
import { formatNumber, I18nPluralPipe } from '@angular/common';

@Pipe({
    name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    transform(value: string) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Pipe({
    name: 'multipleSearchfilter',
})
export class MultipleSearchfilterPipe implements PipeTransform {
    transform(items: any[], fields: string[], value: string): any {
        if (!items) {
            return [];
        }
        if (value === undefined || value === null || value === '') {
            return items;
        }
        return items.filter((it) => {
            const itsStr = fields.map((f) => it[f]) + '';
            return itsStr.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        });
    }
}

@Pipe({
    name: 'multipleSearchFns',
})
export class MultipleSearchFnsPipe implements PipeTransform {
    transform(items: any[], fields: ((item: any) => string)[], value: string): any {
        if (!items) {
            return [];
        }
        if (value === undefined || value === null || value === '') {
            return items;
        }
        return items.filter(
            (it) =>
                fields
                    .map((fn) => fn(it))
                    .toString()
                    .toLowerCase()
                    .indexOf(value.toLowerCase()) !== -1
        );
    }
}

@Pipe({
    name: 'sorting',
})
export class SortingPipe implements PipeTransform {
    transform(
        items: any[],
        column: (item: any) => string | number | boolean,
        direction = 1
    ): any[] {
        if (!items) {
            return [];
        }

        if (!column) {
            return items;
        }

        return [...items].sort((a, b) => {
            const aValue = column(a);
            const bValue = column(b);

            const p1 = typeof aValue === 'number' ? aValue : aValue?.toString().toLowerCase();
            const p2 = typeof bValue === 'number' ? bValue : bValue?.toString().toLowerCase();

            if ((!p1 && p1 !== 0 && p1 !== '') || p1 > p2) {
                return Math.sign(direction);
            }

            if ((!p2 && p2 !== 0 && p1 !== '') || p1 < p2) {
                return -Math.sign(direction);
            }

            return 0;
        });
    }
}

@Pipe({
    name: 'asyncSorting',
})
export class AsyncSortingPipe implements PipeTransform {
    transform = async (
        items: any[],
        column: (item: any) => Promise<string | number | boolean>,
        direction = 1
    ): Promise<any[]> => {
        if (!items) {
            return [];
        }

        if (!column) {
            return items;
        }

        const arr = [...items];

        // bubble sorting
        for (let i = 0, endI = arr.length - 1; i < endI; i++) {
            let wasSwap = false;

            for (let j = 0, endJ = endI - i; j < endJ; j++) {
                let jValue = await column(arr[j]);
                let j1Value = await column(arr[j + 1]);

                jValue = typeof jValue === 'number' ? jValue : jValue?.toString().toLowerCase();
                j1Value = typeof j1Value === 'number' ? j1Value : j1Value?.toString().toLowerCase();

                if ((!jValue && jValue !== 0 && jValue !== '') || jValue < j1Value) {
                    [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
                    wasSwap = true;
                }
            }

            if (!wasSwap) break;
        }

        if (direction === -1) arr.reverse();

        return arr;
    };
}

@Pipe({
    name: 'filterBy',
})
export class FilterByPipe<T> implements PipeTransform {
    transform(items: T[], predicate: (item: T) => boolean): T[] {
        return items?.length ? items.filter(predicate) : items;
    }
}

@Pipe({
    name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
    transform(items: any[], propName: string, direction = 1) {
        const arr = [...items];
        return arr.sort((a, b) => {
            if (propName.indexOf('.') >= 0) {
                const value = propName.split('.');
                const key = value[0];
                const subKey = value[1];
                a = a[key][subKey];
                b = b[key][subKey];
            } else {
                a = a[propName];
                b = b[propName];
            }

            if (a < b) {
                return 1 * direction;
            }
            if (a > b) {
                return -1 * direction;
            }

            return 0;
        });
    }
}
@Pipe({
    name: 'caTime',
})
export class CaTimePipe implements PipeTransform {
    transform(date: string) {
        return `${moment(date).format(`${TIME_FORMAT}`)}`;
    }
}
@Pipe({
    name: 'timeDate',
})
export class TimeDatePipe implements PipeTransform {
    transform(date: string, empty?: string) {
        if (!date) return empty ?? '';
        return `${moment(date).format(`${TIME_FORMAT} DD.MM.YY`)}`;
    }
}
@Pipe({
    name: 'shortData',
})
export class ShortDatePipe implements PipeTransform {
    transform(date: string, empty?: string) {
        if (!date) return empty ?? '';
        return `${moment(date).format('MMM YYYY')}`;
    }
}
@Pipe({
    name: 'shortDateTimeline',
})
export class ShortDateTimelinePipe implements PipeTransform {
    transform(date: string, empty?: string) {
        if (!date) return empty ?? '';
        return `${moment(date).format('D')} ${MONTH_FORMAT(moment(date))}`;
    }
}
@Pipe({
    name: 'shortDateFormat',
})
export class shortDateFormatPipe implements PipeTransform {
    transform(date: string) {
        const locale = LANGUAGE;
        return formatMonthName(
            moment(date).format(
                {
                    ru: `D MMM YYYY, ${TIME_FORMAT}`,
                }[locale] || `MMM Do YYYY, ${TIME_FORMAT}`
            )
        );
    }
}
@Pipe({
    name: 'shortDateFormatWithOrNotYear',
})
export class shortDateFormatWithOrNotYearPipe implements PipeTransform {
    transform(date: string, otherDate: string) {
        const locale = LANGUAGE;
        const beginYear = moment(date).format('YYYY');
        const sameYear = beginYear === moment(otherDate).format('YYYY');
        return sameYear
            ? formatMonthName(
                  moment(date).format(
                      {
                          ru: `D MMM, ${TIME_FORMAT}`,
                      }[locale] || `MMM Do, ${TIME_FORMAT}`
                  )
              )
            : formatMonthName(
                  moment(date).format(
                      {
                          ru: `D MMM YYYY, ${TIME_FORMAT}`,
                      }[locale] || `MMM Do YYYY, ${TIME_FORMAT}`
                  )
              );
    }
}
/**
 * @param {start: string; end: string:string} - dateTime to Iso string format example: 2023-09-11T02:00:00.000Z
 * @param allYear - show year for each time
 * @return {string} - 28 Dec - 29 Dec 2022, all Year 28 Dec 2022 - 29 Dec 2022
 */
@Pipe({
    name: 'dateRangeText',
})
export class dateRangeTextPipe implements PipeTransform {
    transform(date: { start: string; end: string }, allYear: boolean = false) {
        if (!date) {
            return '';
        }
        return dateRangeText(new Date(date.start), new Date(date.end), allYear);
    }
}
/**
 * @param {start: string; end: string:string} - dateTime to Iso string format example: 2023-09-11T02:00:00.000Z
 * @param allYear - show year for each time
 * @return {string} - 28 Dec - 29 Dec 2022, all Year 28 Dec 2022 - 29 Dec 2022
 */
@Pipe({
    name: 'shortDateWithToday',
})
export class shortDateWithTodayPipe implements PipeTransform {
    transform(today: Date, dateTime: string) {
        if (!dateTime) {
            return '';
        }
        return shortDateFormatWithToday(today, dateTime);
    }
}

/**
 * @param {string; string} - dateTime to Iso string format example: 2023-09-11T02:00:00.000Z
 * @return {string} - duration example: 16 h 20 min
 */
@Pipe({
    name: 'durationDateRangeFormat',
})
export class DurationDateRangeFormatPipe implements PipeTransform {
    transform(start: string, end: string) {
        const duration = moment.duration(moment(end).diff(start));
        return formatDuration(duration);
    }
}
/**
 * @param {string; string, dataForTranslate} - dateTime to Iso string format example: 2023-09-11T02:00:00.000Z
 * @return {string} - duration example: 6 hours
 */
@Pipe({
    name: 'durationFullFormat',
})
export class DurationFullFormatPipe implements PipeTransform {
    constructor(private i18nPipe: I18nPluralPipe) {}

    transform(start: string, end: string, textDuration) {
        const duration = moment.duration(moment(end).diff(start));
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        if (years) {
            return `${years} ${this.i18nPipe.transform(years, textDuration.years)}`;
        } else if (months) {
            return `${months} ${this.i18nPipe.transform(months, textDuration.months)}`;
        } else if (days) {
            return `${days} ${this.i18nPipe.transform(days, textDuration.days)}`;
        } else if (hours) {
            return `${hours} ${this.i18nPipe.transform(hours, textDuration.hours)}`;
        } else if (minutes) {
            return `${minutes} ${this.i18nPipe.transform(minutes, textDuration.minutes)}`;
        }
        return '';
    }
}
/**
 * @param {number} - milliseconds
 * @return {string} - duration example: 16 h 20 min
 */
@Pipe({
    name: 'durationDateMsFormat',
})
export class DurationDateMsFormatPipe implements PipeTransform {
    transform(ms: number) {
        const duration = moment.duration(ms);
        return formatDuration(duration);
    }
}
/**
 * @param {any; string} - filesize, locale
 * @return {string} - example 125 kB
 */
@Pipe({
    name: 'filesize',
})
export class FileSizePipe implements PipeTransform {
    transform(value: any, locale: string = 'ru'): any {
        const bytes: number = value ? value : 0;
        const exp: number = (Math.log(bytes) / Math.log(1024)) | 0;
        let result: string = (bytes / Math.pow(1024, exp)).toFixed(1);

        result = result.replace(/\.(0)+$/, '');
        const shortNameSize = locale === 'ru' ? 'КМГЕПЭЗЙ' : 'KMGTPEZY';
        const byteName = locale === 'ru' ? 'Б' : 'B';
        return result + ' ' + (exp == 0 ? 'B' : shortNameSize[exp - 1] + byteName);
    }
}
/**
 * @param {number; string} - distance value example 5000
 * @return {string} - example 5km
 */
@Pipe({
    name: 'distance',
})
export class DistancePipe implements PipeTransform {
    transform(value: number, locale: string = 'ru'): any {
        const dist: number = value ? value : 0;
        if (value < 1000) {
            const distanceName = locale === 'ru' ? 'м' : 'm';
            return `${Math.round(value)} ${distanceName}`;
        }
        const distanceName = locale === 'ru' ? 'км' : 'km';
        return `${Math.round(value / 1000)} ${distanceName}`;
    }
}
const MAX_LABEL_TIMELINE_WIDTH = 55;
const MINUTES_IN_DAYS = 24 * 60;
@Pipe({
    name: 'calculateCurrentTickFreq',
})
export class CalculateCurrentTickFreqPipe implements PipeTransform {
    transform(
        dates: string[],
        width: number
    ): { freq: number; formatFreq: number; format: string } {
        if (!dates[0] || !dates[1] || !width) {
            return null;
        }

        const maxLabelsCount = Math.round(width / MAX_LABEL_TIMELINE_WIDTH);
        const tickInterval = this.getRoundInterval(Math.ceil(dates.length / maxLabelsCount));
        const diffInMinutes = (new Date(dates[1]).valueOf() - new Date(dates[0]).valueOf()) / 60000;
        const minTicksInMinutes = tickInterval * diffInMinutes;
        if (minTicksInMinutes < MINUTES_IN_DAYS) {
            return {
                freq: tickInterval,
                formatFreq: MINUTES_IN_DAYS / diffInMinutes,
                format: TIME_FORMAT,
            };
        } else {
            return {
                freq: tickInterval,
                formatFreq: tickInterval,
                format: TIME_FORMAT,
            };
        }
    }

    private getRoundInterval(interval: number) {
        if (interval <= 2) {
            return interval;
        } else if (interval <= 4) {
            return 4;
        } else if (interval <= 6) {
            return 6;
        } else if (interval <= 12) {
            return 12;
        } else {
            return (Math.trunc(interval / 24) + 1) * 24;
        }
    }
}
@Pipe({
    name: 'needRenderTick',
})
export class NeedRenderTickPipe implements PipeTransform {
    transform(index: number, tickFreq: number): boolean {
        if (tickFreq === 0) {
            return false;
        }

        return index % tickFreq === 0;
    }
}
@Pipe({
    name: 'shortUserName',
})
export class ShortUserNamePipe<T> implements PipeTransform {
    transform(name: string): string {
        return name ? name.substring(0, 2).toUpperCase() : '';
    }
}
@Pipe({
    name: 'noValueFormat',
})
export class NoValueFormatPipe implements PipeTransform {
    transform(value: number | string): string | number {
        return value === null ? '-' : value;
    }
}
@Pipe({
    name: 'valueFormatNumber',
})
export class ValueFormatNumberPipe implements PipeTransform {
    transform(value: number, numberAfterDot: number): string | number {
        const hasNoData = isFalseNumber(value);
        if (hasNoData) {
            return null;
        }

        const formatVal = formatNumber(
            value,
            LANGUAGE,
            '1.' + numberAfterDot + '-' + numberAfterDot
        );
        return customNumberFormat(formatVal, numberAfterDot, LANGUAGE);
    }
}
@Pipe({
    name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
    transform(value: Date | string, currentTime: Date): string {
        return value ? moment(value).fromNow() : '-';
    }
}
