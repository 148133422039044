<div class="onboarding-popup" *ngIf="slides?.[currentSlideIndex] as data" [ngClass]="{'first-slide': currentSlideIndex === 0 }">
    <cross-button
        class="onboarding-popup__close-btn"
        data-cy="onboarding-popup-close"
        size="large"
        (click)="close()"
    ></cross-button>
    <div class="text-block">
        <div class="onboarding-popup__subtitle" *ngIf="data.subtitle">{{ data.subtitle }}</div>
        <div class="onboarding-popup__title">{{ data.title }}</div>
        <div class="onboarding-popup__description">{{ data.description }}</div>
    </div>
    <div class="img-block">
        <img class="onboarding-popup__preview" [attr.src]="data.imgUrl" alt="preview" />
    </div>
    <div class="onboarding-popup__slide-indicator">
        <cs-slide-indicator
            [slides]="slides"
            [currentSlide]="data"
            (gotoSlide)="selectSlide($event)"
        ></cs-slide-indicator>
    </div>
    <div class="onboarding-popup__slide-controls">
        <ng-container *ngIf="currentSlideIndex < slides.length - 1; else done">
            <cs-button-simple data-cy="onboarding-popup-close" (click)="close()"
                >Пропустить</cs-button-simple
            >
            <cs-button-simple type="primary" (click)="nextSlide()">Далее</cs-button-simple>
        </ng-container>
        <ng-template #done>
            <cs-button-simple type="primary" (click)="try()">Попробовать</cs-button-simple>
        </ng-template>
    </div>
    <div class="onboarding-popup__mark-as-seen" (click)="markedAsSeen = !markedAsSeen;close()">
         <span class="onboarding-popup__checkbox-label">Больше не показывать</span>
    </div>
</div>
