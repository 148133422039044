import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import * as forecastActions from './actions';
import { ControlPointForecast, DateRange, ForecastConfig, Station } from '../models';
import { Feature } from '@libs/shared-ui/components/timeline-panel/models/core';

export interface ForecastState {
    dateRange: DateRange;
    currentMmt: string;
    isActiveModule: boolean;
    showLayerOnMap: boolean;
    isValidToken: boolean;
    updateControlPoint: boolean;
    controlPointsError: any;
    activePoint: ControlPointForecast;
    activeStation: Station;
    authErrorLoadList: boolean;
    errorLoadList: any;
    currentTimeIndex: number;
    dates: string[];
    isNeedDatesUpdate: boolean;
    forecastConfig: ForecastConfig[];
    currentConfig: ForecastConfig;
    coordinates: { lat: number; lon: number };
    newControlPoint: ControlPointForecast;
    editControlPointId: string;
    chartData: Feature[];
}
export const initialForecastState: ForecastState = {
    dateRange: null,
    currentMmt: null,
    isActiveModule: false,
    showLayerOnMap: false,
    isValidToken: false,
    updateControlPoint: false,
    controlPointsError: null,
    activePoint: null,
    activeStation: null,
    authErrorLoadList: false,
    errorLoadList: null,
    currentTimeIndex: null,
    dates: [],
    isNeedDatesUpdate: true,
    forecastConfig: null,
    currentConfig: null,
    coordinates: null,
    newControlPoint: null,
    editControlPointId: null,
    chartData: [],
};

const _forecastReducer = createReducer(
    initialForecastState,
    on(forecastActions.updateDateRange, (state: ForecastState, props) => ({
        ...state,
        dateRange: props,
        isNeedDatesUpdate: true,
    })),
    on(forecastActions.toggleModule, (state: ForecastState, props) => ({
        ...state,
        isActiveModule: props.payload,
    })),
    on(forecastActions.toggleLayerOnMap, (state: ForecastState, props) => ({
        ...state,
        showLayerOnMap: props.payload,
    })),
    on(forecastActions.setForecastMeasurement, (state: ForecastState, props) => ({
        ...state,
        currentMmt: props.payload,
    })),
    on(forecastActions.setToken, (state: ForecastState, props) => ({
        ...state,
        isValidToken: props.payload,
    })),
    on(forecastActions.onUpdateControlPoint, (state: ForecastState, props) => ({
        ...state,
        updateControlPoint: props.payload,
    })),
    on(forecastActions.addControlPointError, (state: ForecastState, props) => ({
        ...state,
        controlPointsError: props.payload,
    })),
    on(forecastActions.setActivePoint, (state: ForecastState, props) => ({
        ...state,
        activePoint: props.payload,
    })),
    on(forecastActions.setActiveStation, (state: ForecastState, props) => ({
        ...state,
        activeStation: props.payload,
    })),
    on(forecastActions.setAuthError, (state: ForecastState, props) => ({
        ...state,
        authErrorLoadList: props.payload,
    })),
    on(forecastActions.setErrorLoadControlList, (state: ForecastState, props) => ({
        ...state,
        errorLoadList: props.payload,
    })),
    on(forecastActions.updateTimeIndex, (state: ForecastState, props) => ({
        ...state,
        currentTimeIndex: props.payload,
    })),
    on(forecastActions.datesUpdate, (state: ForecastState, props) => {
        if (state.isNeedDatesUpdate && props.payload.length) {
            const dates = [...props.payload];
            const oldCurrentData = state.dates[state.currentTimeIndex]
                ? state.dates[state.currentTimeIndex]
                : '';
            let currentIndex = dates.indexOf(oldCurrentData);
            if (currentIndex == state.dates.length - 1) {
                currentIndex = -1;
            }
            // if current data update index
            const index = currentIndex == -1 ? 0 : currentIndex;

            return {
                ...state,
                dates: dates,
                currentTimeIndex: index,
                isNeedDatesUpdate: false,
            };
        }

        return state;
    }),
    on(forecastActions.setCoordinates, (state: ForecastState, props) => ({
        ...state,
        coordinates: props.payload,
    })),
    on(forecastActions.setNewControlPoint, (state: ForecastState, props) => ({
        ...state,
        newControlPoint: props.payload,
    })),
    on(forecastActions.setEditControlPointId, (state: ForecastState, props) => ({
        ...state,
        editControlPointId: props.id,
    })),
    on(forecastActions.setChartData, (state: ForecastState, props) => ({
        ...state,
        chartData: props.payload,
    })),
    on(forecastActions.setCurrentConfig, (state: ForecastState, { payload }) => {
        const currentMmt = state.currentMmt ?? payload.config?.species_list[0];
        return {
            ...state,
            currentConfig: payload.config,
            dateRange: payload.dateRange,
            currentMmt,
        };
    })
);

export interface IForecastState {
    core: ForecastState;
}

export function forecastReducer(state, action): any {
    return _forecastReducer(state, action);
}

export const forecastReducers: ActionReducerMap<IForecastState> = {
    core: forecastReducer,
};
