import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

import type { SlideData } from '../../onboarding.type';

@Component({
    selector: 'cs-new-map-onboarding-modal',
    templateUrl: 'new-map-onboarding-modal.component.html',
    styleUrls: ['new-map-onboarding-modal.component.less'],
})
export class NewMapModalComponent {
    @Input() slides: SlideData[];

    @Output() closePopup = new EventEmitter<boolean>();

    currentSlideIndex = 0;

    TEXTS = TEXTS;

    close(markedAsSeen = false) {
        this.closePopup.emit(markedAsSeen);
    }
}
