import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { Feature } from '@libs/common/models/feature';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { measureZones } from '@libs/common/helpers/measure-zones';
import { PdkType } from '@libs/common/types/pdk-type';
import { TEXTS } from '@libs/common/texts/texts';
import { onIsEnabledChart } from '@libs/shared-ui/components/timeline-panel/store/core.actions';
import { LINE_DASH_STYLES } from '@libs/shared-ui/components/timeline-panel/constants';
import { AQI, WDA } from '@libs/common/consts/substance.consts';
import { AqiType } from '@libs/common/enums/aqi.type';
import { MEASUREMENTS_ORDER } from '@libs/common/consts/measurements-order.const';
import { sortMeasurements } from '@libs/common/utils/utils';
import { getUniqueMmts } from '@libs/shared-ui/components/timeline-panel/utils';
import { GroupTooltipsMmt } from '@libs/common/types/group-tooltips-mmt';
import { ANIMATION_CHART_HEIGHT } from '@libs/shared-ui/components/timeline-panel/chart-timeline/chart-timeline.component';
import { Store } from '@ngrx/store';
import { TimelineState } from '@libs/shared-ui/components/timeline-panel/store';
import { getDigitsAfterDot } from '@libs/common/helpers/get-digits-after-dot';

const AQIs = [AQI, ...Object.values(AqiType)];

@Component({
    selector: 'data-list',
    templateUrl: 'data-list.component.html',
    styleUrls: ['./data-list.component.less'],
    animations: [ANIMATION_CHART_HEIGHT],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataListComponent implements OnChanges {
    @Input() isCityMode = false;
    @Input() timeIndex: number;
    @Input() data: Feature[];
    @Input() isCompare: boolean;
    @Input() showCompare: boolean;
    @Input() initSelectMeasurement?: string;
    @Input() labelMode: boolean;
    @Input() groupTooltip: GroupTooltipsMmt;
    @Input() pdk?: { type: PdkType; pdks: { [key in string]: number } };
    @Input() aqiTooltipTemplate: TemplateRef<any>;
    @Input() mmtInfoIcon?: {
        name: string;
        cb: () => void;
    };
    @Input() measureScheme: MeasureScheme;

    @Output() goToCity = new EventEmitter<string>();
    @Output() removeFromComparison = new EventEmitter<string>();
    @Output() setCompareMode = new EventEmitter<void>();
    @Output() setMmts = new EventEmitter<string[]>();

    public TEXTS = TEXTS;
    public dashLinesMap: {
        [key: string]: number;
    } = {};
    public mouseDown = false;
    public startX: any;
    public scrollLeft: any;
    public AQIs = AQIs;
    public AQI = AQI;
    public WDA = WDA;
    public AqiType = AqiType;
    public PdkType = PdkType;
    public measureZones = measureZones;
    public availableMeasurements: string[] = MEASUREMENTS_ORDER;
    public selectedMeasurements: string[] = [];
    public units: Record<string, string>;
    public mmtWithTooltip = ['WDA', 'WVA', 'WVVA'];
    public mmtNameLongArray = ['TEMPERATURE', 'PRESSURE', 'HUMIDITY'];
    public mmtNamesLong = TEXTS.MMT_LONG_NAMES;

    constructor(
        private store: Store<TimelineState>,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data?.currentValue) {
            this.dashLinesMap = this.data.reduce(
                (acc, v, i) => ({
                    ...acc,
                    [v.properties.uuid]: LINE_DASH_STYLES[i]?.join(' '),
                }),
                {}
            );
            this.availableMeasurements = getUniqueMmts(this.data);
            this.updateInitSelectMeasurement();
            this.updateUnits();
            setTimeout(() => {
                this.correctScroll();
            }, 500);
        }
        if (changes.initSelectMeasurement?.currentValue) {
            this.updateInitSelectMeasurement();
        }
    }

    toggleMeasurementAqi(mmt: string) {
        if (this.selectedMeasurements.length === 1 && this.selectedMeasurements.indexOf(mmt) === 0)
            return;

        // deselect
        this.selectedMeasurements.forEach((m) => {
            if (m !== mmt && AQIs.includes(m)) {
                this.toggleMeasurement(m);
            }
        });

        this.toggleMeasurement(mmt);
    }

    toggleMeasurementWithCheck(mmt: string) {
        if (this.selectedMeasurements.length === 1 && this.selectedMeasurements.indexOf(mmt) === 0)
            return;

        this.toggleMeasurement(mmt);
    }

    public getDigitsAfterDot(mmt) {
        return getDigitsAfterDot(this.measureScheme, mmt);
    }

    toggleMeasurementOne(mmt: string) {
        this.selectedMeasurements = [mmt];
        this.setMmts.emit(this.selectedMeasurements);
    }

    public goCity(station: Feature) {
        if (station.properties.obj === 'city' && station.properties.uuid) {
            this.goToCity.emit(station.properties.uuid);
        }
    }

    public onCompare(): void {
        this.setCompareMode.emit();
    }

    closeChart() {
        this.store.dispatch(onIsEnabledChart({ payload: false }));
    }

    public hasScroll() {
        const slider = document.getElementById('data-scrollable');
        if (slider) {
            return slider.scrollWidth > slider.clientWidth;
        } else {
            return false;
        }
    }

    public onWheel(event: WheelEvent): void {
        const slider = document.getElementById('data-scrollable');
        if (slider) {
            slider.scrollLeft += event.deltaY;
        }
        event.preventDefault();
    }

    public startDragging(e, flag, el) {
        this.mouseDown = true;
        this.startX = e.pageX - el.offsetLeft;
        this.scrollLeft = el.scrollLeft;
    }

    public stopDragging() {
        this.mouseDown = false;
    }

    public moveEvent(e, el) {
        e.preventDefault();
        if (!this.mouseDown) {
            return;
        }
        const x = e.pageX - el.offsetLeft;
        const scroll = x - this.startX;
        el.scrollLeft = this.scrollLeft - scroll;
    }

    private updateInitSelectMeasurement() {
        if (!this.data?.length && this.initSelectMeasurement) {
            this.selectedMeasurements = [this.initSelectMeasurement];
        }

        if (
            this.availableMeasurements?.length &&
            !this.availableMeasurements.includes(this.selectedMeasurements[0])
        ) {
            this.selectedMeasurements = [this.availableMeasurements[0]];
        }
        this.setMmts.emit(this.selectedMeasurements);
    }

    private updateUnits() {
        this.units = this.availableMeasurements.reduce(
            (acc, mmt) => ({
                ...acc,
                [mmt]: TEXTS.MEASURES_SCHEME[this.measureScheme][mmt],
            }),
            {}
        );
    }

    private toggleMeasurement(mmt: string) {
        const toDisable = this.selectedMeasurements.includes(mmt);

        if (toDisable) {
            this.selectedMeasurements = this.selectedMeasurements.filter((m) => m !== mmt);
        } else {
            this.selectedMeasurements = this.selectedMeasurements
                .concat(mmt)
                .sort(sortMeasurements);
        }
        this.setMmts.emit(this.selectedMeasurements);
    }

    private correctScroll() {
        const currentEl = document.querySelector(
            '.mobile-measurements_items__item.selected_mmt'
        ) as HTMLElement;
        if (currentEl) {
            currentEl.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    }
}
