<div
    class="onboarding-popup"
    [attr.data-slide-id]="data.id"
    *ngIf="slides?.[currentSlideIndex] as data"
>
    <div class="onboarding-popup__subtitle">Что нового?</div>
    <div class="onboarding-popup__title">{{ data.title }}</div>
    <div
        *ngIf="data.description"
        class="onboarding-popup__description"
        [innerHTML]="data.description"
    ></div>
    <img
        *ngIf="data.id === 'chart'"
        class="onboarding-popup__preview"
        [attr.src]="data.imgUrl"
        alt="preview"
    />
    <img
        *ngIf="data.id === 'login'"
        class="onboarding-popup__preview"
        [attr.src]="data.imgUrl"
        alt="preview"
    />
    <div *ngIf="data.id === 'settings'" class="onboarding-popup__video-wrap">
        <video
            loop
            autoPlay
            playsInline
            onloadedmetadata="this.muted = true"
            oncanplay="this.play()"
            [attr.poster]="data.posterUrl"
            class="onboarding-popup__video"
        >
            <source [attr.src]="data.videoUrl" type="video/mp4" />
        </video>
    </div>
    <div *ngIf="data.id === 'types'" class="onboarding-popup__video-wrap">
        <video
            loop
            autoPlay
            playsInline
            onloadedmetadata="this.muted = true"
            oncanplay="this.play()"
            [attr.poster]="data.posterUrl"
            class="onboarding-popup__video"
        >
            <source [attr.src]="data.videoUrl" type="video/mp4" />
        </video>
    </div>
    <div class="onboarding-popup__bottom">
        <div class="onboarding-popup__slide-indicator">
            <cs-slide-indicator
                [slides]="slides"
                [currentSlide]="data"
                (gotoSlide)="selectSlide($event)"
            ></cs-slide-indicator>
        </div>
        <div class="onboarding-popup__slide-controls">
            <ng-container *ngIf="currentSlideIndex === 0">
                <div class="button-simple button-simple__primary" (click)="nextSlide()">Далее</div>
            </ng-container>
            <ng-container *ngIf="currentSlideIndex > 0 && currentSlideIndex < slides.length - 1">
                <cs-button-simple data-cy="onboarding-popup-close" (click)="previousSlide()"
                    >Назад</cs-button-simple
                >
                <div class="button-simple button-simple__primary" (click)="nextSlide()">Далее</div>
            </ng-container>
            <ng-container *ngIf="currentSlideIndex === slides.length - 1">
                <cs-button-simple data-cy="onboarding-popup-close" (click)="previousSlide()"
                    >Назад</cs-button-simple
                >
                <div
                    class="button-simple button-simple__primary button-simple_width-xl"
                    (click)="close()"
                >
                    Начать пользоваться
                </div>
            </ng-container>
        </div>
        <div class="onboarding-popup__mark-as-seen" (click)="close()">
            <span class="onboarding-popup__checkbox-label">Больше не показывать</span>
        </div>
    </div>
    <div
        class="onboarding-popup__close-btn"
        data-cy="onboarding-popup-close"
        (click)="close()"
    ></div>
</div>
