import { LngLatBounds } from 'mapbox-gl';

export enum SETTINGS_PAGES {
    settings = 'settings',
    system = 'system',
    keys = 'keys',
    groupSettings = 'group-settings',
    groupSettingsMap = 'map-settings',
    groupSettingsCoef = 'coefficient-group',
    groupSettingsStartModule = 'start-module',
    groupSettingsDefaultPost = 'default-post',
    users = 'users',
    edit = 'edit',
    add = 'add',
}
export const SETTINGS_INIT_PAGE = SETTINGS_PAGES.system;
export const USER_ICON_COLORS = ['#FAD86B', '#A6C9FF', '#A877FE', '#EE70C7', '#91A4BF', '#A0DCD0'];
export const DEFAULT_BOUNDS = new LngLatBounds(
    {
        lng: -360,
        lat: -84,
    },
    {
        lng: 360,
        lat: 84,
    }
);
export const DRAW_STYLES = [
    // ACTIVE (being drawn)

    // polygon fill
    {
        id: 'gl-draw-polygon-fill',
        type: 'fill',
        filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
        paint: {
            'fill-color': '#000000',
            'fill-outline-color': '#000000',
            'fill-opacity': 0.1,
        },
    },
    // polygon mid points
    {
        id: 'gl-draw-polygon-midpoint',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
        paint: {
            'circle-radius': 3,
            'circle-color': '#A6C9FF',
        },
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
        id: 'gl-draw-polygon-stroke-active',
        type: 'line',
        filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#FFFFFF',
            'line-width': 4,
        },
    },
    // vertex point halos
    {
        id: 'gl-draw-polygon-and-line-vertex-halo-active',
        type: 'circle',
        filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'circle-radius': 7,
            'circle-color': '#A6C9FF',
        },
    },
    // vertex points
    {
        id: 'gl-draw-polygon-and-line-vertex-active',
        type: 'circle',
        filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'circle-radius': 4,
            'circle-color': '#A6C9FF',
        },
    },

    // INACTIVE (static, already drawn)
    // line stroke
    {
        id: 'gl-draw-line-static',
        type: 'line',
        filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#FFFFFF',
            'line-width': 4,
        },
    },
    // polygon fill
    {
        id: 'gl-draw-polygon-fill-static',
        type: 'fill',
        filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
        paint: {
            'fill-opacity': 0,
        },
    },
    // polygon outline
    {
        id: 'gl-draw-polygon-stroke-static',
        type: 'line',
        filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#FFFFFF',
            'line-width': 4,
        },
    },
];
