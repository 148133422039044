<div class="impact-post-pin"
    [ngClass]="{'impact-post-pin_state-selected': selected, 'impact-post-pin_state-expand': isExpand}"
>
    <div class="impact-post-pin__body" [ngStyle]="{ backgroundColor: isExpand ? 'white' : color, border: selected && !isExpand ? '4px solid ' + color : '4px solid #fff' }"
         [ngClass]="{ 'with-contribution': !!data, 'expand': isExpand }"
    >
        <ng-container *ngIf="!isExpand else contentTemp">
            <div class="wrapper-value" >
                <div class="impact-post-pin__value"
                     (click)="select()"
                >
                    <ng-container *ngIf="value===null else valueTemp">
                        -
                    </ng-container>
                    <ng-template #valueTemp>
                         {{ value | valueFormatNumber : numberAfterDot }}
                    </ng-template>
                </div>
                <div class="additionalData" (click)="toggleContent($event)">
                    <span class="collapse-icon expand"></span>
                </div>
            </div>
        </ng-container>
        <ng-template #contentTemp>
            <div class="wrapper-content">
                <div class="title-row">
                    <div class="name">{{station?.name}}</div>
                    <div class="collapse-button" (click)="toggleContent($event)">
                        {{texts.expandControlPoint}}
                        <span class="collapse-icon collapse"></span>
                    </div>
                </div>
                <div class="value-row">
                    <div class="value-block">
                        <div class="title">{{texts.contribution.titlePost}}</div>
                        <div class="circle" [style]="{ background: color }"></div>
                        <div class="mmt" *ngIf="mmtData" [innerHtml]="textNames[mmtData.mmt] + ': '"></div>
                        <div class="units-block">
                            <div class="value" *ngIf="value !== null"> {{ value | valueFormatNumber : numberAfterDot }}</div>
                            <div class="value" *ngIf="value === null"> - </div>
                            <div class="units" *ngIf="mmtData as val" [innerHtml]="textScheme[val.scheme][val.mmt]"></div>
                        </div>
                    </div>
                    <div class="time-block">{{currentTime | shortDateFormat}}</div>
                </div>
                <div class="value-row">
                    <div class="value-block">
                        <div class="title">{{texts.contribution.titleRun}}</div>
                        <div class="circle" [style]="{ background: colorRun }"></div>
                        <div class="mmt" *ngIf="mmtData" [innerHtml]="textNames[mmtData.mmt] + ': '"></div>
                        <div class="units-block">
                            <div class="value"> {{ valueRun | valueFormatNumber : numberAfterDot }}</div>
                            <div class="units" *ngIf="mmtData as val" [innerHtml]="textScheme[val.scheme][val.mmt]"></div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="isLoading else showContent">
                    <div class="no-data-wrapper">
                        <div class="no-data">
                            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
                        </div>
                    </div>
                </ng-container>
                <ng-template #showContent>
                    <div class="contributions">
                        <div class="chart-title">{{texts.contributionTitle}}:</div>
                        <div class="contributions__wrapper" *ngIf="(valueRun) && datasets else noData">
                            <div class="contributions-chart">
                                <canvas
                                    baseChart
                                    type="doughnut"
                                    [datasets]="datasets"
                                    [labels]="labels"
                                    [options]="options"
                                ></canvas>
                            </div>

                            <div class="contributions__items_wrapper">
                                <div *ngFor="let val of data; index as i">
                                    <div *ngIf="val" class="contributions__item">
                                        <div class="name-wrapper">
                                            <span class="circle" [style]="{ background: backgroundColor[i] }"></span>
                                            <span class="point" [innerHtml]="labels[i]"></span>
                                        </div>
                                        <span class="contributions__percent">{{ val?.value | number: '1.0-0': currentLang }}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #noData>
                            <ng-container *ngIf="error else noData"><div class="no-data-wrapper">
                                <div class="no-data">{{texts.errorLoadContribution}}</div>
                            </div>
                            </ng-container>
                            <ng-template #noData>
                                <div class="no-data-wrapper">
                                    <div class="no-data">{{texts.noContributes}}</div>
                                </div>
                            </ng-template>
                        </ng-template>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </div>
    <div class="impact-post-pin__line"></div>
    <div
        class="impact-post-pin__end"
        [style]="{ backgroundColor: color }"
    ></div>
</div>

