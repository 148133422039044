import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { QueryParams } from '@ngrx/data';
import { LANGUAGE } from '@libs/common/texts/texts';
import { ACCESS_TOKEN_KEY } from '@cityair/namespace';
import { StorageService } from '@cityair/modules/login/services/auth/storage.service';
import {
    GroupUserParams,
    NotificationQueryParams,
    NotificationSettings,
    PostCreateRequest,
    RegionCoefsDto,
    User,
    BasicUserTokens,
    IBasicResponse,
    FeedItemsExportRequest,
    StationDataToExcelRequestProps,
    MoItemsDataToExcelRequestProps,
    UserSettings,
    RegionCoefsResponse,
} from '@libs/common/models/basicModels';
import { WebResponse } from '@cityair/modules/login/services/harvester-api/models';
import { URLS } from '@cityair/modules/core/services/api/urls';
import * as moment from 'moment-timezone';

const TIMELINE_END_POINT = 'timeline';
const GROUP_END_POINT = 'group';
const SUMMARY_END_POINT = 'summary';
const ANALYSIS_END_POINT = 'analysis';
const CORRELATIONS_END_POINT = 'correlations';
const WIND_END_POINT = 'wind';

@Injectable({
    providedIn: 'root',
})
export class BasicApi {
    private accessToken = '';
    constructor(private http: HttpClient, private storageService: StorageService) {}

    getUser(): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.me}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getUsers(groupId: number | string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.groupUser}?${GROUP_END_POINT}=${groupId}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    createUser(user: GroupUserParams): Observable<any> {
        const httpOptions = this.getHttpOptions(user);
        const url = `${URLS.groupUser}`;
        return this.http.post<IBasicResponse>(url, user, httpOptions);
    }

    updateUser(user: GroupUserParams): Observable<any> {
        const httpOptions = this.getHttpOptions(user);
        const url = `${URLS.groupUser}`;
        return this.http.put<IBasicResponse>(url, user, httpOptions);
    }

    deleteUser(userId: string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.groupUser}${userId}`;
        return this.http.delete<IBasicResponse>(url, httpOptions);
    }

    updateUserSettings(settings: UserSettings): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.userSettings}`;
        return this.http.post<IBasicResponse>(url, settings, httpOptions);
    }

    getGroups(): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.group}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    updateGroupExtConfig(groupId: number | string, data: any): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.group}${groupId}/settings`;
        return this.http.put<IBasicResponse>(url, data, httpOptions);
    }

    getAllPost(groupId: number | string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.post}?${GROUP_END_POINT}=${groupId}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getAllLocality(groupId: number | string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.locality}?${GROUP_END_POINT}=${groupId}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getAllDevices(groupId: number | string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.device}?${GROUP_END_POINT}=${groupId}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getDataTimeline(groupId: number | string, params: QueryParams): Observable<any> {
        const httpOptions = this.getHttpOptions(params);
        const url = `${URLS.group}${groupId}/${TIMELINE_END_POINT}/`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getAnalysisData(groupId: number | string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.networkAnalysis}${CORRELATIONS_END_POINT}?${GROUP_END_POINT}=${groupId}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getAnalysisWindData(params: QueryParams): Observable<any> {
        const httpOptions = this.getHttpOptions(params);
        const url = `${URLS.networkAnalysis}${WIND_END_POINT}/`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getGroupEvents(params: QueryParams): Observable<any> {
        const httpOptions = this.getHttpOptions(params);
        const url = `${URLS.events}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getEventById(groupId: string, id: string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.events}${id}?${GROUP_END_POINT}=${groupId}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getCorrelationTimelines(params: QueryParams): Observable<any> {
        const httpOptions = this.getHttpOptions(params);
        const url = `${URLS.correlationTimelines}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getCorrelationDataByPost(postId: string, params: QueryParams): Observable<any> {
        const httpOptions = this.getHttpOptions(params);
        const url = `${URLS.post}${postId}/${ANALYSIS_END_POINT}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getNetworkAnalysis(groupId: string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.networkAnalysis}?${GROUP_END_POINT}=${groupId}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getPostDataTimeline(postId: number | string, params: QueryParams): Observable<any> {
        const httpOptions = this.getHttpOptions(params);
        const url = `${URLS.post}${postId}/${TIMELINE_END_POINT}/`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getCityDataTimeline(cityId: number | string, params: QueryParams): Observable<any> {
        const httpOptions = this.getHttpOptions(params);
        const url = `${URLS.locality}${cityId}/${TIMELINE_END_POINT}/`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getSummary(groupId: number | string, locality_id: string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.locality}${locality_id}/${SUMMARY_END_POINT}/?${GROUP_END_POINT}=${groupId}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    addPost(params: PostCreateRequest): Observable<any> {
        const httpOptions = this.getHttpOptions(params);
        const url = `${URLS.post}`;
        return this.http.post(url, params, httpOptions);
    }

    updatePost(id: number | string, params: PostCreateRequest): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.post}${id}/`;
        return this.http.put<IBasicResponse>(url, params, httpOptions);
    }

    deletePost(id: number | string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.post}${id}/`;
        return this.http.delete(url, httpOptions);
    }

    getAllNotificationsSettings(groupId: number | string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.notificationSettings}?${GROUP_END_POINT}=${groupId}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    getAllNotificationsEvents(params: NotificationQueryParams): Observable<any> {
        const httpOptions = this.getHttpOptions(params);
        const url = `${URLS.notifications}`;
        return this.http.get<IBasicResponse>(url, httpOptions);
    }

    markAsViewNotification(id: string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const params = { is_viewed: true };
        const url = `${URLS.notifications}${id}/`;
        return this.http.put<IBasicResponse>(url, params, httpOptions);
    }

    addNotificationsSettings(params: NotificationSettings): Observable<any> {
        const httpOptions = this.getHttpOptions(params);
        const url = `${URLS.notificationSettings}`;
        return this.http.post(url, params, httpOptions);
    }

    updateNotificationsSettings(params: NotificationSettings): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.notificationSettings}${params.id}/`;
        return this.http.put<IBasicResponse>(url, params, httpOptions);
    }

    deleteNotificationsSettings(params: NotificationSettings): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = `${URLS.notificationSettings}${params.id}`;
        return this.http.delete<IBasicResponse>(url, httpOptions);
    }

    // old api
    foundUsers(text: string): Observable<any> {
        const params = {
            Item: { Email: text },
            Token: this.getAccessToken(),
        };
        return this.http.post<WebResponse<any>>(URLS.userIsExist, params);
    }

    getUserPermission(user: User): Observable<any> {
        const params = {
            Item: {
                Email: user.email,
                Login: user.login,
                UserId: user.id,
            },
            Token: this.getAccessToken(),
        };
        return this.http.post<WebResponse<any>>(URLS.userPermission, params);
    }

    deleteUserToken(token: BasicUserTokens): Observable<any> {
        const params = {
            Item: token,
            Token: this.getAccessToken(),
        };
        return this.http.post<WebResponse<any>>(URLS.deleteUserToken, params);
    }

    updateUserToken(token: BasicUserTokens): Observable<any> {
        const params = {
            Item: token,
            Token: this.getAccessToken(),
        };
        return this.http.post<WebResponse<any>>(URLS.renameApiKey, params);
    }

    getUserApiKeyToken(token: BasicUserTokens): Observable<any> {
        const params = {
            Item: token,
            Token: this.getAccessToken(),
        };
        return this.http.post<WebResponse<any>>(URLS.tokenValue, params);
    }

    setRegionCoefsItem(data: RegionCoefsDto): Observable<any> {
        const params = {
            Item: {
                Description: data.description,
                Name: data.name,
                Pcf: data.fix_pcf,
                RegionCoefsId: Number(data.id),
                Scf: data.fix_scf,
            },
            Token: this.getAccessToken(),
        };
        return this.http.post<WebResponse<RegionCoefsResponse>>(URLS.changeRegionCoefs, params);
    }

    saveMoDataToExcel(props: MoItemsDataToExcelRequestProps): Observable<any> {
        const ids = props.moIds?.map((v) => v.replace('post_', ''));
        const params = {
            TimeBegin: new Date(props.timeBegin - new Date().getTimezoneOffset() * 60000),
            TimeEnd: new Date(props.timeEnd - new Date().getTimezoneOffset() * 60000),
            IntervalType: props.interval,
            MoIds: ids,
            Lang: LANGUAGE.toLocaleUpperCase(),
            TimeZone: moment().utcOffset() / 60,
            ExportType: props.type,
            UnitsType: props.unitsType,
            InsertPdk: props.insertPdk,
            Token: this.getAccessToken(),
        };
        return this.http.post<WebResponse<any>>(URLS.exportData, params);
    }

    saveMoData(props: MoItemsDataToExcelRequestProps): Observable<any> {
        const ids = props.moIds?.map((v) => v.replace('post_', ''));
        const token = this.getAccessToken();
        const params = {
            TimeBegin: new Date(props.timeBegin - new Date().getTimezoneOffset() * 60000),
            TimeEnd: new Date(props.timeEnd - new Date().getTimezoneOffset() * 60000),
            IntervalType: props.interval,
            MoIds: ids,
            Lang: LANGUAGE.toLocaleUpperCase(),
            TimeZone: moment().utcOffset() / 60,
            ExportType: props.type,
            UnitsType: props.unitsType,
            InsertPdk: props.insertPdk,
            Token: token,
        };
        return this.http.post(URLS.exportDataPost, params, {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + token,
            }),
            responseType: 'blob',
        });
    }

    saveDeviceDataToExcel(props: StationDataToExcelRequestProps): Observable<any> {
        const params = {
            DeviceExportType: props.type,
            DateBegin: new Date(props.timeBegin - new Date().getTimezoneOffset() * 60000),
            DateEnd: new Date(props.timeEnd - new Date().getTimezoneOffset() * 60000),
            DeviceIds: props.ids,
            Lang: LANGUAGE.toLocaleUpperCase(),
            TimeZone: moment().utcOffset() / 60,
            Token: this.getAccessToken(),
        };
        return this.http.post<any>(URLS.exportDataDevice, params);
    }

    exportNotificationsBySettings(data: FeedItemsExportRequest): Observable<any> {
        const params = { ...data, Token: this.getAccessToken() };
        return this.http.post(URLS.exportNotification, params);
    }

    private getHttpOptions(
        params?:
            | string
            | QueryParams
            | HttpParams
            | PostCreateRequest
            | NotificationQueryParams
            | GroupUserParams
            | NotificationSettings
    ): any {
        const token = this.getAccessToken();

        const headers = {
            Authorization: `Bearer ${token}`,
            'Accept-Language': LANGUAGE,
        };

        if (params) {
            return Object.assign({}, { headers }, { params });
        }

        return { headers };
    }

    private getAccessToken() {
        return this.accessToken || (this.accessToken = this.storageService.get(ACCESS_TOKEN_KEY));
    }
}
