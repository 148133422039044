<div class="add-report-wrapper" *ngIf="!noFoundTemplate">
    <cross-button class="close-button" size="large" (click)="close()"></cross-button>
    <div class="add-report-form-wrapper scroll" *ngIf="currentKind && id !== marketingReportId else otherTemplate">
        <div class="item">
            <div class="title">
                {{ isRu ? currentKind.name : currentKind.name_eng }}
            </div>
            <div class="desc">
                {{ isRu ? currentKind.description : currentKind.description_eng}}
            </div>
        </div>
        <div class="form-wrapper" *ngIf="ReportCreateForm">
            <form (ngSubmit)="onSubmit()" [formGroup]="ReportCreateForm" novalidate>
                <div class="name-wrapper">
                    <ca-input
                        type="text"
                        controlName="name"
                        [form]="ReportCreateForm"
                        [valueForm]="formName.value"
                        [label]="labelTexts.name"
                        [textError]="getError('name')"
                    ></ca-input>
                </div>
                <div *ngIf="currentKind.params">
                    <div class="date-range-wrapper" *ngIf="showDateRange">
                        <report-date-range
                            class="input-date-range"
                            [timeBegin]="params.timeBegin"
                            [timeEnd]="params.timeEnd"
                            [displayMonth]="1"
                            [minDate]="store.select(selectGroupCreateDate) | async"
                            [labelText]="labelTexts.calendar"
                            (timeChange)="changeCalendar($event)"
                        ></report-date-range>
                    </div>
                    <div class="dropdown-list" *ngIf="showDateRange">
                        <report-form-dropdown
                            [values]="tzList"
                            [defaultValue]="tzValue"
                            [key]="PARAMS_KEY.timezone"
                            [search]="true"
                            (setValue)="setValueDropDown($event)"
                        ></report-form-dropdown>
                    </div>
                    <div
                        class="dropdown-list"
                        *ngIf="this.currentKind?.params[PARAMS_KEY.availableFormats] as format"
                    >
                        <report-form-dropdown
                            [values]="format.values"
                            [defaultValue]="format.default"
                            [key]="PARAMS_KEY.availableFormats"
                            (setValue)="setValueDropDown($event)"
                        ></report-form-dropdown>
                    </div>
                    <div
                        class="dropdown-list"
                        *ngIf="currentKind?.params[PARAMS_KEY.timezone] as timezone"
                    >
                        <report-form-dropdown
                            [values]="tzList"
                            [defaultValue]="tzValue"
                            [key]="PARAMS_KEY.timezone"
                            [search]="true"
                            (setValue)="setValueDropDown($event)"
                        ></report-form-dropdown>
                    </div>
                    <div class="dropdown-list" *ngIf="currentKind?.params[PARAMS_KEY.year] as year">
                        <report-form-dropdown
                            [values]="year.values"
                            [defaultValue]="year.default"
                            [key]="PARAMS_KEY.year"
                            [search]="false"
                            (setValue)="setValueDropDown($event)"
                        ></report-form-dropdown>
                    </div>
                    <div
                        class="dropdown-list"
                        *ngIf="currentKind?.params[PARAMS_KEY.measure] as measure"
                    >
                        <report-measure-select-list
                            [required]="true"
                            [mode]="'edit'"
                            [list]="allSubstance"
                            [selected]="
                                measure.default === 'all' ? measure.values : [measure.default]
                            "
                            [multiSelect]="false"
                            [available]="availableSubstance"
                            (setSelected)="setMeasure($event)"
                        ></report-measure-select-list>
                        <div class="error-msg" style="margin-top: 10px;" *ngIf="getError(PARAMS_KEY.measure) as msg">
                            {{msg}}
                        </div>
                    </div>
                    <div
                        class="dropdown-list"
                        *ngIf="currentKind?.params[PARAMS_KEY.threshold] as threshold"
                    >
                        <ca-input
                            type="number"
                            controlName="threshold"
                            [step]="threshold_step_value"
                            [form]="ReportCreateForm"
                            [valueForm]="formThreshold.value"
                            [label]="textsNotification.excessLevelReport"
                            [textError]="getError('threshold')"
                        ></ca-input>
                        <div class="gradient_slider">
                            <ca-gradient-slider
                                [min]="threshold_min_value"
                                [max]="threshold_max_value"
                                [step]="threshold_step_value"
                                [sliderModel]="formThreshold.value"
                                (sliderModelChange)="updateSliderValue($event)"
                            ></ca-gradient-slider>
                        </div>
                    </div>
                    <div
                        class="select-mo-wrapper"
                        *ngIf="currentKind?.params[PARAMS_KEY.postIds]"
                    >
                        <ng-container *ngIf="isShowPostSelect else noPostTemp">
                            <input-dropdown
                                class="post-report-tza__input-dropdown"
                                [inputText]="selectedCheckboxText(getTags().length)"
                                [label]="textsPostDevices.postsMonitoring"
                                [(showDropdown)]="isShowDropdownForStations"
                                (clickOutside)="isShowDropdownForStations = false"
                                [textError]="
                                    getTags().length === 0 ? translateText.formCreate.postMoError : ''
                                "
                            >
                                <cs-selectbox *ngIf="postsSelectedMany else radioTemp"
                                    [listItems]="listStations"
                                    (listItemsChange)="updateListStation($event)"
                                    [searchPlaceholderText]="postMonitoringSearchText"
                                    [selectAllText]="selectAllText"
                                ></cs-selectbox>
                                <ng-template #radioTemp>
                                    <selectbox-radio
                                        [listItems]="listStations"
                                        (listItemsChange)="updateListStation($event)"
                                    ></selectbox-radio>
                                </ng-template>
                            </input-dropdown>
                            <tags-list
                                class="tags-list"
                                [tags]="getTags()"
                                [textNodesFns]="postsListLabels()"
                                [truncateTo]="2"
                                [editable]="true"
                                (removeTag)="removeFromList($event)"
                            ></tags-list>
                        </ng-container>
                        <ng-template #noPostTemp>
                            <div class="error-msg">
                                {{translateText.noPostsMsg}}
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="switch-item-wrapper" *ngIf="currentKind?.params[PARAMS_KEY.intervals]">
                    <div class="title-item">{{ textsPostDevices.intervalData }}</div>
                    <switch-item
                        class="post-report__switch-item"
                        [currentTab]="currentIntervalTab"
                        [tabs]="intervalTabs"
                        (action)="setTabsInterval($event)"
                    ></switch-item>
                </div>
                <div class="switch-item-wrapper" *ngIf="currentKind?.params[PARAMS_KEY.units] ">
                    <div class="title-item">{{ textsPostDevices.units }}</div>
                    <div class="post-report__end-block">
                        <switch-item
                            [currentTab]="currentUnitTab"
                            [tabs]="unitTabs"
                            (action)="setUnit($event)"
                        ></switch-item>
                    </div>
                </div>
                <div class="fileupload-wrapper" *ngIf="currentKind?.params[PARAMS_KEY.fileupload] as uploadParams">
                     <div class="file-upload-label">{{translateText.keyParams[PARAMS_KEY.fileupload]}}</div>
                     <cityair-file-upload
                         [params]="uploadParams"
                         (setFile)="setFile($event)"
                     ></cityair-file-upload>
                 </div>
                <div class="comments-wrapper">
                    <ca-textarea
                        type="text"
                        controlName="comment"
                        [form]="ReportCreateForm"
                        [valueForm]="formComment.value"
                        [label]="labelTexts.commentPlaceholder"
                        [textError]="getError('comment')"
                        [heightValue]="100"
                    ></ca-textarea>
                </div>
                <div class="button-wrapper">
                    <btn-cancel class="cancel-button" (cbClick)="close()">
                        {{ cancelText }}
                    </btn-cancel>
                    <btn-accept
                        class="submit-button"
                        (cbClick)="onSubmit()"
                        [disabled]="ReportCreateForm.invalid || checkMo()"
                        [isLoading]="this.loadingResponse"
                    >
                        {{ translateText.generateReport }}
                    </btn-accept>
                </div>
            </form>
        </div>
        <div class="error-response-wrapper" *ngIf="errorResponse">
            <div class="title-status">{{ translateText.formCreate.errorTitle }}</div>
            {{ errorResponse }}
        </div>
    </div>
    <ng-template #otherTemplate>
        <div *ngIf="id === marketingReportId">
            <cityair-demo-report [currentKind]="currentKind"></cityair-demo-report>
        </div>
    </ng-template>
</div>
<div class="no-found-template" *ngIf="noFoundTemplate">
    <div class="no-data-wrapper period">
        <non-data-screen [text]="''"></non-data-screen>
    </div>
    <div class="no-found-title">{{ translateText.noFoundKind }}</div>
    <div class="button-return-wrapper">
        <button class="return-button">{{ translateText.returnReportButtonText }}</button>
    </div>
    <div class="return-text-wrapper">
        <div class="text-return">{{ translateText.noFoundKindReturn }}</div>
    </div>
</div>
