<div class="onboarding-popup" *ngIf="slides?.[currentSlideIndex] as data">
    <cross-button
        class="onboarding-popup__close-btn"
        data-cy="onboarding-popup-close"
        size="large"
        (click)="close()"
    ></cross-button>
    <div class="text-block">
        <div class="onboarding-popup__subtitle" *ngIf="data.subtitle">{{ data.subtitle }}</div>
        <div class="onboarding-popup__title">{{ data.title }}</div>
        <div class="onboarding-popup__description">{{ data.description }}</div>
    </div>
    <div class="img-block">
        <img class="onboarding-popup__preview" [attr.src]="data.imgUrl" alt="preview" />
    </div>
    <div class="onboarding-popup__slide-controls">
        <cs-button-simple type="primary" (click)="try()">Начать пользоваться</cs-button-simple>
    </div>
    <div class="onboarding-popup__mark-as-seen" (click)="markedAsSeen = !markedAsSeen;close()">
         <span class="onboarding-popup__checkbox-label">Больше не показывать</span>
    </div>
</div>
