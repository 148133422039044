import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import type { SlideData } from '../../onboarding.type';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { Router } from '@angular/router';

@Component({
    selector: 'added-reports-modal',
    templateUrl: 'added-reports-modal.component.html',
    styleUrls: ['added-reports-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddedReportsModalComponent {
    @Input() slides: SlideData[];
    @Output() closePopup = new EventEmitter<boolean>();

    markedAsSeen = false;
    currentSlideIndex = 0;

    constructor(private router: Router) {}

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.close();
    }

    close() {
        this.closePopup.emit(this.markedAsSeen);
    }

    try() {
        this.closePopup.emit(true);
        this.router.navigate([`/${MAIN_PAGES.reports}`]);
    }
}
