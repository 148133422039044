import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule, DefaultDataServiceConfig } from '@ngrx/data';

import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { SortListItemModule } from '@cityair/libs/shared/components/sort-list-item/sort-list-item.module';
import { KebabMenuModule } from '@cityair/libs/shared/components/kebab-menu/kebab-menu.module';
import { PageTabsModule } from '@libs/shared-ui/components/page-tabs/page-tabs.module';
import { OverlayModule } from '@cityair/libs/shared/modules/overlay/overlay.module';
import { ButtonAddModule } from '@libs/shared-ui/components/button-add/button-add.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { NonDataScreenModule } from '@cityair/components/non-data-screen/non-data-screen.module';
import { HeaderOfPanelModule } from '@libs/shared-ui/components/header-of-panel/header-of-panel.module';
import { InputModule } from '@libs/shared-ui/components/input/input.module';
import { InputForCoordinateModule } from '@cityair/components/input-for-coordinate/input-for-coordinate.module';
import { BtnAcceptModule } from '@libs/shared-ui/components/btn-accept/btn-accept.module';
import { BtnExtraModule } from '@libs/shared-ui/components/btn-extra/btn-extra.module';
import { SectionLineModule } from '@cityair/components/section-line/section-line.module';
import { PopupAskModule } from '@cityair/components/popup-ask/popup-ask.module';
import { CardActionsModule } from '@cityair/components/card-actions/card-actions.module';
import { StaticTagsModule } from '@libs/shared-ui/components/static-tags/static-tags.module';
import { CityscreenComponentsModule } from '@cityair/components/cityscreen-components.module';
import { ColorLegendModule } from '@cityair/components/color-legend/color-legend.module';
import { CalendarMonthsModule } from '@cityair/libs/common/calendar/calendar-months/calendar-months.module';
import { ControlPointsComponent } from './components/control-points/control-points.component';
import { ControlPointComponent } from './components/control-point/control-point.component';
import { ForecastMainComponent } from './components/forecast-main/forecast-main.component';
import { ForecastMapComponent } from './components/forecast-map/forecast-map.component';
import { CreateControlPointComponent } from './components/create-control-point/create-control-point.component';
import { ForecastTopElements } from './components/forecast-top-elements/forecast-top-elements';
import { ForecastCalendarComponent } from './components/forecast-top-elements/forecast-calendar/forecast-calendar.component';
import { MapStyleSelectorComponent } from '@libs/shared-ui/components/map-style-selector/map-style-selector.component';

import { FORECAST_API_URL } from './constants';
import { forecastRoutes } from './forecast-routing';
import { forecastReducers } from './store/reducers';
import { ForecastEffects } from './store/effects';
import { entityForecastConfig } from './entity-metadata';
import { EntityStoreForecastModule } from './entity-store.module';
import { BtnCancelModule } from '@libs/shared-ui/components/btn-cancel/btn-cancel.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { AqiDetailsTooltipModule } from '@libs/shared-ui/components/aqi-details-tooltip/aqi-details-tooltip.module';
import { TimelinePanelModule } from '@libs/shared-ui/components/timeline-panel/timeline-panel.module';
import { MapControlModule } from '@libs/shared-ui/components/map-control/map-control.module';
import { MmtSelectorModule } from '@libs/shared-ui/components/mmt-selector/mmt-selector.module';
import { InputForDropdownModule } from '@libs/shared-ui/components/input-for-dropdown/input-for-dropdown.module';
import { GetValueControlPointPipe } from './pipes/get-value-control-point.pipe';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
    root: FORECAST_API_URL,
};

@NgModule({
    declarations: [
        ControlPointsComponent,
        ControlPointComponent,
        ForecastMainComponent,
        CreateControlPointComponent,
        ForecastTopElements,
        ForecastCalendarComponent,
        ForecastMapComponent,
    ],
    imports: [
        RouterModule,
        RouterModule.forChild(forecastRoutes),
        CommonModule,
        StoreModule.forFeature('forecast', forecastReducers),
        EffectsModule.forFeature([ForecastEffects]),
        EntityDataModule.forRoot(entityForecastConfig),
        EntityStoreForecastModule,
        FormsModule,
        ReactiveFormsModule,
        PageTabsModule,
        OverlayModule,
        ButtonAddModule,
        LoadersModule,
        NonDataScreenModule,
        HeaderOfPanelModule,
        InputModule,
        InputForCoordinateModule,
        BtnAcceptModule,
        BtnCancelModule,
        BtnExtraModule,
        SectionLineModule,
        SortListItemModule,
        PipesModule,
        KebabMenuModule,
        PopupAskModule,
        StaticTagsModule,
        CardActionsModule,
        DirectivesModule,
        CityscreenComponentsModule,
        CalendarMonthsModule,
        AqiDetailsTooltipModule,
        ColorLegendModule,
        TimelinePanelModule,
        MapControlModule,
        MmtSelectorModule,
        InputForDropdownModule,
        MapStyleSelectorComponent,
        GetValueControlPointPipe
    ],
    exports: [ForecastMapComponent],
    providers: [
        {
            provide: DefaultDataServiceConfig,
            useValue: defaultDataServiceConfig,
        },
    ],
})
export class ForecastModule {}
